import React, { useEffect, useState } from 'react'
import Pagination from 'components/molecules/Pagination/Pagination'
import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import TemplateCardAndAssociatedAtoms from '../../molecules/TemplateCardAndAssociatedAtoms/TemplateCardAndAssociatedAtoms'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { getTemplates } from 'services/templateService'
import { useNavigate } from 'react-router-dom'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import { TemplateRulesList } from 'interfaces/templates'

export default function TemplatesList() {
    const { apiToken } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        totalItems: 0,
        page: 1,
        itemsPerPage: 10,
    })

    const [templates, setTemplates] = useState<TemplateRulesList[]>([])

    const changePage = (page: number) => {
        setPagination({ ...pagination, page })
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const templates = await getTemplates(pagination.page)
            setTemplates(templates.items)
            setPagination({
                page: templates.page,
                totalItems: templates.totalItems,
                itemsPerPage: templates.size,
            })
            setLoading(false)
        }

        fetchData()
    }, [apiToken, pagination.page])

    return (
        <div className="h-5/6">
            {loading && <LoaderTransparent />}
            <div className="px-9 h-5/6">
                <BreadcrumbTitle
                    title="Plantillas"
                    subtitle="Variedad de plantillas para elegir, lo que permite a los usuarios personalizar su contenido según sus necesidades específicas. Además, la herramienta puede generar tus propias plantillas, lo que permite llegar a los requerimientos puntuales."
                />
                <div className=" flex flex-col justify-between h-full mt-8">
                    <div
                        className="overflow-y-auto"
                        style={{ height: '500px' }}
                    >
                        {templates.length > 0 ? (
                            templates.map((template, index) => (
                                <TemplateCardAndAssociatedAtoms
                                    key={index}
                                    name={template.name}
                                    rules={template.count_groups}
                                    assigned={template.count_chutes}
                                    onSubmit={() =>
                                        navigate(
                                            `/templates/show/${template.id}`
                                        )
                                    }
                                />
                            ))
                        ) : (
                            <div className="flex justify-center p-4 text-sm">
                                no hay plantillas para mostrar
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center mt-10">
                        <Pagination
                            totalPages={Math.ceil(
                                pagination.totalItems / pagination.itemsPerPage
                            )}
                            pagAct={pagination.page}
                            currentPage={pagination.page}
                            onPageChange={changePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
