export const withCriteria = [
    {
        title: 'Tracking',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Provider code',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Service code',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Service type',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Value',
        value: 'Requerido | Decimal',
    },
    {
        title: 'Weight',
        value: 'Requerido | Decimal',
    },
    {
        title: 'Width',
        value: 'Opcional | Decimal',
    },
    {
        title: 'Height',
        value: 'Opcional | Decimal',
    },
    {
        title: 'Length',
        value: 'Opcional | Decimal',
    },
    {
        title: 'Zip code',
        value: 'Opcional | Text | Max: 255',
    },
    {
        title: 'DDP/DDU',
        value: 'Requerido | “Y or N”',
    },
    {
        title: 'Bag',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Air waybill',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Client Code',
        value: 'Opcional | Text | Max: 255',
    },
]
export const withoutCriteria = [
    {
        title: 'Tracking',
        value: 'Requerido | Text | Max: 255',
    },
    {
        title: 'Chute',
        value: 'Requerido | Integer | "1 - 42"',
    },
]
