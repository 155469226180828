import ItemChute from 'components/atoms/ItemChute/ItemChute'
import React from 'react'
interface Props {
    list: Record<string, number[]>
    name?: string | null
}

const ListCardChutesOthers = ({ list, name }: Props) => {
    return (
        <div className="flex flex-col gap-5 py-5 px-7 bg-white rounded-3xl shadow-md">
            <p className="text-xl font-bold">{name}</p>
            <>
                {Object.entries(list).map(([key, values]) => (
                    <ItemChute
                        key={key}
                        chutes={values}
                        title={key}
                        theme="active"
                        onClick={() => {
                            console.log('redirect')
                        }}
                    />
                ))}
            </>
        </div>
    )
}

export default ListCardChutesOthers
