import { ReactComponent as NextLogo } from '../../../assets/nextIcon.svg'

interface Props {
    onClick: () => void
}

const ButtonNextNavigation = ({ onClick }: Props) => {
    return <NextLogo className="cursor-pointer" onClick={onClick} />
}
export default ButtonNextNavigation
