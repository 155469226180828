import React, { useEffect, useState } from 'react'
import AppSwitch from '../../atoms/AppSwitch/AppSwitch'

interface Props {
    label: string,
    checkPrefix?: string,
    checkSuffix?: string,
    checked: boolean,
    onChange: (e:boolean) => void
}
const SwitchCriteriaForm = ({label, checked, onChange, checkSuffix, checkPrefix}:Props) => {
    const [dataForm, setData] = useState(false)

    useEffect(() => {
        setData(checked)
    }, [checked])

    return (
        <div>
            <p className='mb-6'>{
                label
            }</p>
            <div className="flex w-full items-center">
                {
                    checkPrefix && <p className={'mr-2'}>{checkPrefix}</p>
                }

                <AppSwitch checked={dataForm} onChange={(v) => onChange(v)} />
                {
                    checkSuffix && <p className={'ml-2'}>{checkSuffix}</p>
                }
            </div>
        </div>
    )
}

export default SwitchCriteriaForm
