import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AppRoutes from './AppRoutes'
import AuthRoutes from './AuthRoutes'
import useCheckAuth from '../hooks/useCheckAuth'
import Loader from 'components/molecules/Loader/Loader'
import { RootState } from '../store/store';

const AppRouter = () => {
  const { isLoading } = useSelector((state: RootState) => state.auth)
    const status = useCheckAuth()

    if (status === 'checking' || isLoading) return <Loader />

    return (
        <Routes>
            {status === 'authenticated' ? (
                <Route path="/*" element={<AppRoutes />} />
            ) : (
                <Route path="/auth/*" element={<AuthRoutes />} />
            )}

            <Route path="/*" element={<Navigate to="/auth/login" />} />
        </Routes>
    )
}

export default AppRouter
