import React from 'react'
import { ReactComponent as ReactLogoProgress } from '../../../assets/progress.svg'
import { ReactComponent as ReactLogoFinished } from '../../../assets/finished.svg'
import ResumeCard from 'components/molecules/ResumeCard/ResumeCard'
import RuleDetailAndChuteCard from 'components/molecules/RuleDetailAndChuteCard/RuleDetailAndChuteCard'
import { OrderDetails } from 'interfaces/order'
import { formatDate, formatHour } from 'utils/formatting'
import { orderStates, orderStatesSelect } from 'utils/constants'

interface Props {
    order: OrderDetails
}

export default function ShowOrderDetails({ order }: Props) {
    const componentLogo = () => {
        return order && order.state === 'FINISHED' ? (
            <div>{<ReactLogoFinished className="cursor-pointer" />}</div>
        ) : (
            <div>{<ReactLogoProgress className="cursor-pointer" />}</div>
        )
    }

    const componentTotals = () => {
        return (
            <div className="flex justify-between items-center flex-col h-full w-full">
                <p className="text-4xl font-extrabold text-slate-600">
                    {order.totalProcessed} / {order.totalWithoutProcessed} /{' '}
                    {order.total}
                </p>
                <div className="flex w-full">
                    <div className="flex items-end w-full justify-center">
                        <p className="text-xs font-normal text-slate-600">
                            Procesados
                        </p>
                    </div>
                    <div className="flex items-end text-center w-full justify-center">
                        <p className="text-xs font-normal text-slate-600">
                            No <br /> Procesados
                        </p>
                    </div>
                    <div className="flex items-end w-full justify-center">
                        <p className="text-xs font-normal text-slate-600">
                            Total
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const componentCreatedAt = () => {
        return (
            <div className="flex justify-between items-center flex-col h-full">
                <p className="text-xl font-bold text-slate-600 text-center  ">
                    {formatDate(order.createdAt)} a las <br />{' '}
                    {formatHour(order.createdAt)}
                </p>
            </div>
        )
    }
    return (
        <div className="px-5 h-5/6 flex flex-col mt-14">
            <div className="flex gap-x-8 justify-between">
                <ResumeCard
                    component={componentLogo}
                    subtitle={
                        order.state
                            ? orderStatesSelect[order.state as orderStates]
                                  .label
                            : ''
                    }
                />
                <ResumeCard component={componentTotals} />
                <ResumeCard
                    component={componentCreatedAt}
                    subtitle="Creada el"
                />
            </div>
            <div className="mt-14 overflow-y-auto max-h-[500px] grid grid-cols-2 gap-4">
                {Object.entries(order.groups).map(([ruleName, rule], index) => (
                    <RuleDetailAndChuteCard
                        key={index}
                        name={ruleName}
                        lastUpdate={
                            rule.last_update
                                ? formatDate(rule.last_update) +
                                  ' a las ' +
                                  formatHour(rule.last_update)
                                : 'Fecha no disponible'
                        }
                        total={String(rule.total_packages)}
                        totalPackages={Number(rule.total)}
                        chutes={rule.chutes}
                    />
                ))}
            </div>
        </div>
    )
}
