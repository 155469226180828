import React, { useEffect, useState } from 'react'
import BreadcrumbTitle from '../../atoms/BreadcrumbTitle/BreadcrumbTitle'
import { useNavigate, useParams } from 'react-router-dom'
import CardSelectRuleForm from 'components/molecules/CardSelectRuleForm/CardSelectRuleForm'
import AppButton from 'components/atoms/AppButton/AppButton'
import SelectChute from 'components/molecules/SelectChute/SelectChute'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import {
    getTemplateRules,
    addChute,
    getTemplateDetail,
} from '../../../services/templateService'
import {
    TemplateRule,
    TemplateDetail,
    DetailChute,
    Chute,
} from '../../../interfaces/templates'
import Message from '../../atoms/Message/Message'
import { getConfigurationChutesForTemplate } from 'services/ordersService'
interface TemplatesAssign {
    error: boolean
    data: number[]
    message: string
}

interface TemplateRules {
    error: boolean
    data: TemplateRule[]
    message: string
}

export default function Templates() {
    const navigate = useNavigate()
    const [showChutes, setShowChutes] = useState(false)
    const [ruleId, setRuleId] = useState<number | null>(null)
    const [rulesTemplates, setRulesTemplates] = useState<TemplateRules>()
    const [chutesList, setChutesList] = useState<TemplatesAssign | null>(null)
    const [chutesAssign, setChutesAssign] = useState<TemplateDetail | null>(
        null
    )
    const [newChutes, setNewChutes] = useState<Chute[] | null>(null)
    const [nameRule, setNameRule] = useState('')
    const [loading, setLoading] = useState(false)
    const addRule = (data: number) => {
        const found = rulesTemplates?.data.find((obj) => {
            return obj.id === data
        })
        if (found) {
            setNameRule(found?.name)
        }
        setRuleId(data)
        setShowChutes(true)
    }
    const { id } = useParams()

    const submitAssign = async () => {
        try {
            if (id !== undefined) {
                await addChute(id, {
                    group_id: ruleId,
                    chutes: chutesList ? chutesList?.data : [],
                })
                navigate(`/templates/show/${id}`)
            }
        } catch (error) {
            // @ts-expect-error error for any
            setChutesList(error)
        }
    }

    useEffect(() => {
        const getRules = async () => {
            try {
                const data = await getTemplateRules(parseInt(id + ''))
                setRulesTemplates(data)
            } catch (error) {
                // @ts-expect-error error for any
                setRulesTemplates(error)
            }
        }
        const getChutesTemplateDetail = async () => {
            try {
                setLoading(true)
                if (id !== undefined) {
                    const data = await getTemplateDetail(parseInt(id))
                    const numerosChutes = data.groups.reduce(
                        (acumulador: number[], grupo: DetailChute) => {
                            return acumulador.concat(grupo.chutes)
                        },
                        []
                    )
                    setChutesAssign({ ...data, groups: numerosChutes })
                }
            } catch (error) {
                // @ts-expect-error error for any
                setChutesAssign(error)
            } finally {
                setLoading(false)
            }
        }
        const getChutesList = async () => {
            try {
                setLoading(true)
                if (id !== undefined) {
                    const data = await getConfigurationChutesForTemplate(
                        parseInt(id)
                    )
                    setNewChutes(
                        data.data.default_chutes.sort(
                            (a: Chute, b: Chute) => a.chute - b.chute
                        )
                    )
                }
            } catch (error) {
                // @ts-expect-error error for any
                setChutesAssign(error)
            } finally {
                setLoading(false)
            }
        }
        getRules()
        getChutesTemplateDetail()
        getChutesList()
    }, [])

    return (
        <>
            {loading ? (
                <LoaderTransparent />
            ) : (
                <div className="px-10">
                    <BreadcrumbTitle
                        title={
                            chutesAssign && !showChutes
                                ? `${chutesAssign?.name}`
                                : `${chutesAssign?.name} - ${nameRule}`
                        }
                        subtitle={
                            showChutes
                                ? 'Elección de chutes para la regla.'
                                : ''
                        }
                        goToBack={
                            !showChutes
                                ? () => navigate('/templates')
                                : () => {
                                      setShowChutes(false)
                                  }
                        }
                    />
                    <div className="flex justify-center h-full  pt-5 ">
                        {showChutes ? (
                            <div className="w-[510px] flex flex-col justify-center">
                                <SelectChute
                                    onChange={(value) => {
                                        // @ts-expect-error error for any
                                        setChutesList({
                                            ...chutesList,
                                            data: value,
                                        })
                                    }}
                                    chutes={newChutes}
                                />
                                {chutesAssign && chutesAssign.message && (
                                    <Message
                                        message={chutesAssign.message}
                                        type={'error'}
                                    />
                                )}
                                <div className="flex justify-center">
                                    <AppButton
                                        disabled={
                                            !(
                                                chutesList &&
                                                chutesList.data.length > 0
                                            )
                                        }
                                        title="Asignar Chutes"
                                        onClick={submitAssign}
                                        className="mt-5"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <CardSelectRuleForm
                                    title="Selecciona la regla"
                                    subtitle="Selecciona la regla que agregaras a la nueva plantilla"
                                    onClick={(data) => addRule(data)}
                                    items={
                                        rulesTemplates?.data !== undefined
                                            ? rulesTemplates.data
                                            : []
                                    }
                                />
                                {rulesTemplates?.message && (
                                    <Message
                                        message={rulesTemplates.message}
                                        type={'error'}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
