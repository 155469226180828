import { OrderDetailsResponse } from 'interfaces/order'

export const formatDate = (dateString: string) => {
    const date = new Date(dateString)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`

    return formattedDate
}

export const formatHour = (dateString: string) => {
    const date = new Date(dateString)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')

    const formattedDate = `${hours}:${minutes}`

    return formattedDate
}

export const formatOrderDetailsResponse = (data: OrderDetailsResponse) => {
    return {
        createdAt: data.created_at,
        description: data.description,
        groups: data.groups,
        id: data.id,
        state: data.state,
        total: data.total,
        totalProcessed: data.total_processed,
        totalWithoutProcessed: data.total_without_processed,
    }
}
