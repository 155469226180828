import { Criteria } from 'interfaces/rule'
import React from 'react'

interface Props {
    title: string
    criterias: Criteria[]
    criterias_selected: string[]
}
export default function RuleItem({
    title,
    criterias,
    criterias_selected,
}: Props) {
    return (
        <div className="border-2 rounded-lg py-4 px-6 bg-white mb-4">
            <h1 className="text-base mb-5">{title}</h1>
            {criterias.map((criteria, index) => {
                return (
                    <span
                        key={index}
                        className={`${
                            criterias_selected.includes(
                                criteria.criteriaType.name
                            )
                                ? 'bg-blue-600 text-white'
                                : 'bg-slate-200	text-black'
                        } text-xs font-medium me-2 px-2 py-1 rounded dark:bg-blue-900 dark:text-blue-300`}
                    >
                        {criteria.criteriaType.name}
                    </span>
                )
            })}
        </div>
    )
}
