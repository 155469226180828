import React from 'react'
interface Props {
    list: number[]
    name?: string
}

const ListCardChutesAvailables = ({ list, name }: Props) => {
    return (
        <div className="flex flex-col gap-5 py-5 px-7 bg-white rounded-3xl shadow-md overflow-x-auto">
            <p className="text-xl font-bold">{name}</p>
            <div className="flex gap-3 w-max">
                {Array.isArray(list) &&
                    list.map((chute, index) => (
                        <h1
                            key={index}
                            className="flex justify-center items-center rounded-full bg-blue-600 w-[30px] h-[30px] text-white"
                        >
                            {chute}
                        </h1>
                    ))}
            </div>
        </div>
    )
}

export default ListCardChutesAvailables
