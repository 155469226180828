import React from 'react'
import { ReactComponent as NextLogo } from '../../../assets/nextIcon.svg'

interface Props {
    name: string
    rules: number
    assigned: number
    onSubmit: () => void
}
export default function TemplateCardAndAssociatedAtoms({
    name,
    rules,
    assigned,
    onSubmit,
}: Props) {
    return (
        <div className="w-full border flex justify-between items-center px-7 py-7 bg-white rounded-md mb-4">
            <div className="text-left">
                <h2 className="font-normal text-base text-black">{name}</h2>
            </div>
            <div className="flex items-center gap-5">
                <div className="text-right">
                    <h3 className="text-base font-bold text-gray-400">
                        {rules} Reglas
                    </h3>
                    <h3 className="text-xs font-bold text-gray-400 mt-1">
                        {assigned} Asignados
                    </h3>
                </div>
                <NextLogo onClick={onSubmit} className="cursor-pointer" />
            </div>
        </div>
    )
}
