import React from 'react'
import MenuList from 'components/molecules/MenuList/MenuList'
import { menuOptions } from 'utils/menuOptions'

const Menu = () => {
    return (
        <div className="w-1/6">
            <div className='sticky top-10'>
                <div className="mt-12 flex justify-center">
                    <img
                        src={require('../../../assets/Icon.png')}
                        className="img-logo"
                        alt="Logo"
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <MenuList data={menuOptions} />
                </div>
            </div>
        </div>
    )
}

export default Menu
