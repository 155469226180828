export const menuOptions = [
    {
        title: 'Órdenes',
        url: 'orders',
        state: 'active',
    },
    {
        title: 'Reglas',
        url: 'rules',
        state: 'inactive',
    },
    {
        title: 'Plantillas',
        url: 'templates',
        state: 'inactive',
    },
    // {
    //     title: 'Usuarios',
    //     url: 'users',
    //     state: 'inactive',
    // },
    // {
    //     title: 'Ajustes',
    //     url: 'settings',
    //     state: 'inactive',
    // },
    {
        title: 'Cerrar sesión',
        url: 'logout',
        state: 'sign-off',
    },
]
