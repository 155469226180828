import React, { ReactNode } from 'react'

interface Props {
    children?: ReactNode
}

export default function HeaderPageSection({ children }: Props) {
    return (
        <div className="w-full h-20 flex items-center justify-end px-10 mt-10">
            {children}
        </div>
    )
}
