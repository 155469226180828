import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../interfaces/user'
import { fetchUser } from './userThunks'

export interface userState {
    user: User | null,
    status: 'checking' | 'authenticated' | 'not-authenticated',
    isLoading: boolean,
    errorMessage: string
}

const initialState: userState = {
    user: null ,
    status: 'not-authenticated',
    isLoading: false,
    errorMessage: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setChecking: (state) => {
            state.status = 'checking'
        },
        setUser: (state, action:PayloadAction<User>) => {
            state.user = action.payload,
            state.status = 'authenticated'
        },
        clearUser: (state) => {
            state.user = initialState.user
            state.status = initialState.status
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.user;
                state.errorMessage = ''
                state.status = 'authenticated'
            })
            .addCase(fetchUser.rejected, (state) => {
                state.isLoading = false;
                state.user = null;
                state.errorMessage = ''
            });
    },
})

// Action creators are generated for each case reducer function
export const { setChecking, setUser, clearUser } = userSlice.actions

export default userSlice.reducer
