import React, { useState } from 'react'
import './styles.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type SelectedDate = Date | null
interface Props {
    sendDate: (data: { start: Date | null; end: Date | null }) => void
}
export default function AppDate({ sendDate }: Props) {
    // Estado para la fecha de inicio y la fecha de fin
    const [startDate, setStartDate] = useState<SelectedDate>(null)
    const [endDate, setEndDate] = useState<SelectedDate>(null)

    // Función para manejar el cambio de la fecha de inicio
    const handleStartDateChange = (date: SelectedDate) => {
        setStartDate(date)
        sendDate({ end: endDate, start: date })
    }

    // Función para manejar el cambio de la fecha de fin
    const handleEndDateChange = (date: SelectedDate) => {
        setEndDate(date)
        sendDate({ end: date, start: startDate })
    }

    return (
        <div className="flex justify-center ">
            <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                className="date date-l"
                dateFormat="dd/MM"
                placeholderText="DD/MM"
            />
            <h2 className="date-m text-xs text-ebony-950/75 flex items-center">
                Hasta
            </h2>
            <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="date date-r"
                placeholderText="DD/MM"
                dateFormat="dd/MM"
                minDate={startDate} // Evita que la fecha de fin sea anterior a la fecha de inicio
            />
        </div>
    )
}
