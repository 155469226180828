import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CriteriaCreate, CriteriaElement, CriteriaList } from '../../../interfaces/rule'



interface RuleCreateState extends CriteriaCreate{
    criteriaSelection: CriteriaList[]
}

const initialState: RuleCreateState = {
    name: '',
    description:'',
    criterias: [],
    criteriaSelection: []
}

export const ruleCreateSlice = createSlice({
    name: 'ruleCreate',
    initialState,
    reducers: {
        setName: (state, action:PayloadAction<string>) => {
            state.name = action.payload
        },
        setCriteriaSelection: (state, action:PayloadAction<CriteriaList[]>) => {
            state.criteriaSelection = action.payload
        },
        setCriterias: (state, action:PayloadAction<CriteriaElement[]>) => {
            state.criterias = action.payload
        },
        resetState: (state) => {
            state.name = initialState.name
            state.description = initialState.description
            state.criterias = initialState.criterias
            state.criteriaSelection = initialState.criteriaSelection
        }
    }
})

// Action creators are generated for each case reducer function
export const { setName, setCriteriaSelection, setCriterias, resetState } = ruleCreateSlice.actions

export default ruleCreateSlice.reducer
