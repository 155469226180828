import { trackingAxios } from '../config/api/tracking'
import {
    CriteriaCreate,
    CriteriasListResponse,
    Filter,
    RulesListResponse,
    ServiceCodesResponse,
} from 'interfaces/rule'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const criteriasList = async () => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get<CriteriasListResponse>(
            'criteria-types',
            {
                headers,
            }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rulesList = async (filters: Filter, token: string | null) => {
    const criteriaTypesString = filters.criteria_types.join(',')
    const params = {
        ...filters,
        criteria_types: criteriaTypesString,
    }
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get<RulesListResponse>('groups', {
            params: params,
            headers,
        })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const createRule = async (rule: CriteriaCreate) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.post('/groups', rule, { headers })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as UserLoginError
    }
}

export const getServiceCodes = async (country_code?: string) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const params = country_code ? { country_code } : {}
        const response = await trackingAxios.get<ServiceCodesResponse>(
            'externals/service-codes',
            {
                headers,
                params,
            }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getServiceTypes = async () => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get<ServiceCodesResponse>(
            'externals/service-types',
            {
                headers,
            }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getClients = async (country_code?: string) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const params = country_code ? { country_code } : {}
        const response = await trackingAxios.get<ServiceCodesResponse>(
            'externals/clients',
            {
                headers,
                params,
            }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getCountries = async () => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get('/externals/countries', {
            headers,
        })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getPostalStructures = async (country: string, level: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }

        const config = {
            params: {
                country_code: country,
                level: level,
            },
            headers: headers,
        }
        const response = await trackingAxios.get(
            '/externals/postal-structures',
            config
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}
