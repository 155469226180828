import Menu from '../components/organisms/menu/Menu'
import Content from './Content'
import { ReactNode } from 'react'
import Footer from './Footer'

interface Props {
    children: ReactNode
}

const Layout = ({ children }: Props) => {
    return (
        <div className="relative w-11/12 m-auto pt-8 pb-16">
            <div className="mb-20">
                <div className="flex">
                    <Menu />
                    <Content>{children}</Content>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Layout
