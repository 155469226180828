import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import Message from 'components/atoms/Message/Message'
import ListCardChutesAssigned from 'components/molecules/ListCardsChutesAssigned/ListCardChutesAssigned'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTemplateDetail } from 'services/templateService'
import { RootState } from 'store/store'

interface Props {
    template: number
}

export default function TemplateRulesList({ template }: Props) {
    const navigate = useNavigate()
    const { apiToken } = useSelector((state: RootState) => state.auth)
    const [loading, setLoading] = useState(false)
    const [rulesList, setRulesList] = useState({
        id: null,
        name: '',
        groups: [],
    })
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await getTemplateDetail(template)
                setRulesList(data)
                setLoading(false)
            } catch (error) {
                setErrorMessage('Ha ocurrido un error intente mas tarde')
                setLoading(false)
            }
        }

        fetchData()
    }, [apiToken])

    return (
        <div>
            {loading && <LoaderTransparent />}
            <div className="px-10">
                <BreadcrumbTitle
                    title={`${rulesList.name} # ${template}`}
                    subtitle="Visualizar información de órdenes y filtrar busquedas."
                    goToBack={() => navigate(-1)}
                />
                <div className="mt-10">
                    {rulesList.groups.length > 0 ? (
                        <ListCardChutesAssigned list={rulesList.groups} />
                    ) : (
                        <div className="flex justify-center p-4 text-sm">
                            no hay reglas para mostrar
                        </div>
                    )}
                    {errorMessage && (
                        <Message message={errorMessage} type={'error'} />
                    )}
                </div>
            </div>
        </div>
    )
}
