import AppSelect from 'components/atoms/AppSelect/AppSelect'
import SelectList from '../../../molecules/SelectList/SelectList'
import { useEffect, useState } from 'react'
import { CriteriaElement, PostalStructure } from 'interfaces/rule'

export interface Country {
    name: string
    code: string
}

interface Props {
    criteria_type_id: number
    title: string
    countries: Country[]
    OnHandleChangeList: (country: string) => Promise<void>
    onChange: (e: CriteriaElement) => void
    /* eslint-disable @typescript-eslint/no-explicit-any */
    list1: any
}

export default function GeolocationCriteriaForm({
    criteria_type_id,
    title,
    countries,
    OnHandleChangeList,
    onChange,
    list1,
}: Props) {
    const [select1, setSelect1] = useState<PostalStructure[]>([])
    const [selectCountry, setSelectCountry] = useState('')

    useEffect(() => {
        if (list1?.length > 0) {
            setSelect1(list1)
        } else {
            setSelect1([])
        }
    }, [list1])

    const handleCountryChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = event.target.value
        setSelectCountry(selectedValue)
        OnHandleChangeList(selectedValue)
    }

    const [select2, setSelect2] = useState<PostalStructure[]>([])

    const [select1Aux, setSelect1Aux] = useState<PostalStructure[]>([])
    const [select2Aux, setSelect2Aux] = useState<PostalStructure[]>([])

    useEffect(() => {
        onChange(listItemToCriteriaData())
    }, [select2])

    const listItemToCriteriaData = (): CriteriaElement => {
        return {
            criteria_type_id: criteria_type_id,
            operator: '=',
            first_value: select2.map((item) => item.name).join(','),
            second_value: selectCountry,
        }
    }

    const handleAddItem = (newValue: PostalStructure) => {
        if (!select1Aux.some((item) => item.name === newValue.name)) {
            setSelect1Aux((prevState) => [...prevState, newValue])
        }
    }

    const handleRemoveItem = (newValue: PostalStructure) => {
        if (!select2Aux.some((item) => item.name === newValue.name)) {
            setSelect2Aux((prevState) => [...prevState, newValue])
        }
    }

    const addOptions = () => {
        const uniqueItemsToAdd = select1Aux.filter(
            (item) =>
                !select2.some((existingItem) => existingItem.name === item.name)
        )
        setSelect2((prevState) => [...prevState, ...uniqueItemsToAdd])
        setSelect1((prevState) =>
            prevState.filter(
                (item) => !uniqueItemsToAdd.some((j) => j.name === item.name)
            )
        )
        setSelect1Aux([])
    }

    const removeOptions = () => {
        const uniqueItemsToRemove = select2Aux.filter(
            (item) =>
                !select1.some(
                    (existingCountry) => existingCountry.name === item.name
                )
        )
        setSelect1((prevState) => [...prevState, ...uniqueItemsToRemove])
        setSelect2((prevState) =>
            prevState.filter(
                (item) =>
                    !uniqueItemsToRemove.some(
                        (itemToRemove) => itemToRemove.name === item.name
                    )
            )
        )
        setSelect2Aux([])
    }

    return (
        <div className="flex justify-between">
            <div className="w-full">
                <p>{title}</p>
                <div className="">
                    <AppSelect
                        label="Selecciona un Pais"
                        defaultValue="Mexico"
                        options={countries.map((country) => ({
                            label: country.name,
                            value: country.code,
                        }))}
                        onChange={handleCountryChange}
                    />
                </div>
                <SelectList
                    key={JSON.stringify(select1)}
                    list={select1}
                    title="Region"
                    onClick={handleAddItem}
                />
            </div>
            <div className="flex flex-col justify-end px-2">
                <img
                    src={require('../../../../assets/sendElements.png')}
                    alt="send"
                    className="cursor-pointer"
                    onClick={addOptions}
                />
                <img
                    src={require('../../../../assets/backElements.png')}
                    alt="back"
                    className="cursor-pointer mb-20 mt-3"
                    onClick={removeOptions}
                />
            </div>
            <div className="w-full flex items-end">
                <div className="mt-7 w-full">
                    <SelectList
                        list={select2}
                        title="localidades"
                        onClick={handleRemoveItem}
                    />
                </div>
            </div>
        </div>
    )
}
