import AppButton from 'components/atoms/AppButton/AppButton'
import HeaderPageSection from 'components/molecules/HeaderPageSection/HeaderPageSection'
import TemplateShowOrder from 'components/organisms/TemplateShowOrder/TemplateShowOrder'
import Layout from 'layout/Layout'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function ShowTemplatePage() {
    const { orderId } = useParams()
    const navigate = useNavigate()
    const orderIdNumber = Number(orderId)

    return (
        <Layout>
            <HeaderPageSection>
                <div>
                    <AppButton
                        title="Cargar nueva orden"
                        size="sm"
                        state="ghost"
                        className="ml-4 text-sm font-bold"
                        onClick={() => navigate('/orders/create')}
                    />
                </div>
            </HeaderPageSection>
            <TemplateShowOrder orderId={orderIdNumber} />
        </Layout>
    )
}
