import axios, { AxiosResponse } from 'axios'
import { tracking } from '../index'

// export const trackingAxios = axios.create({baseURL: tracking.apiUrl+'/api/v1/tracking-app'})
export const trackingAuth = axios.create({ baseURL: tracking.apiUrl + '/auth' })
export const trackingAxios = axios.create({
    baseURL: tracking.apiUrl + '/api/v1',
})

const interceptorResponse = (response: AxiosResponse) => {
    return response
}

trackingAxios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token')

        if (token !== null) {
            // @ts-expect-error type validation error
            config.headers = {
                Authorization: `Bearer ${token}`,
                ...config.headers,
            }
        }

        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

trackingAuth.interceptors.response.use(interceptorResponse, async (error) => {
    if (
        error.response?.status === 401 &&
        error.response?.data.message === 'Unauthorized'
    ) {
        localStorage.removeItem('token')
        window.location.href = '/'
        return await Promise.resolve()
    }

    return await Promise.reject(error)
})
