import { trackingAxios } from '../config/api/tracking'
import {
    Order,
    OrderResponse,
    OrderError,
    OrdersFilters,
    OrdersListResponse,
    ShowOrderResponse,
} from 'interfaces/order'
import { orderStates } from '../utils/constants'
import { formatOrderDetailsResponse } from 'utils/formatting'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const create = async (order: Order, token: string | null) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
        const response = await trackingAxios.post<OrderResponse>(
            'orders',
            { ...order },
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createWithoutCriteria = async (
    order: Order,
    token: string | null
) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
        const response = await trackingAxios.post<OrderResponse>(
            'orders/no-template',
            { ...order },
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ordersList = async (
    filters: OrdersFilters,
    token: string | null
) => {
    try {
        const headers = {
            Authorization: `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get<OrdersListResponse>('orders', {
            params: filters,
            headers,
        })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

export const updateState = async (orderId: number, state: orderStates) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.patch<OrderResponse>(
            'orders/' + orderId,
            { state },
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

export const showOrder = async (orderId: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get<ShowOrderResponse>(
            'orders/' + orderId + '/chutes',
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

export const showOrderDetails = async (orderId: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get('orders/' + orderId, {
            headers,
        })

        const data = formatOrderDetailsResponse(response.data)
        return data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as OrderError
    }
}

export const getOrderReport = async (orderId: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get(
            'orders/' + orderId + '/report',
            {
                headers,
            }
        )

        return response
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response
    }
}

export const getConfigurationChutesForTemplate = async (templateId: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get(
            'configurations/templates/' + templateId,
            {
                headers,
            }
        )

        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response
    }
}
