import React from 'react'
import './styles.css'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
    title: string
    size?: 'sm' | 'md' | 'lg'
    state?: 'primary' | 'ghost' | 'dark' | 'default' | 'secondary' | 'info'
    buttonType?: 'button' | 'submit'
    onClick?: () => void
    rounded?: boolean
}

const typeMapper = {
    primary: 'success',
    info: 'info',
    ghost: 'ghost',
    dark: 'dark',
    default: 'default',
    secondary: 'secondary'
}

const sizeMapper = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
}

const AppButton = ({
    title,
    size = 'md',
    state = 'primary',
    buttonType = 'button',
    disabled = false,
    className,
    rounded,
    ...rest
}: Props) => {
    const typeClass = typeMapper[state]
    const sizeClass = sizeMapper[size]

    return (
        <button
            type={buttonType}
            className={
                `button ${typeClass} ${sizeClass} ${rounded && 'rounded-xl'} ${disabled ? 'disabled ' : ''
                }` + className
            }
            disabled={disabled}
            {...rest}
        >
            {title}
        </button>
    )
}

export default AppButton
