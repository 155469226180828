import React, { useState, useEffect } from 'react'
import InputFileUpload from 'components/atoms/InputFileUpload/InputFileUpload'
import OrderForm from 'components/molecules/OrderForm/OrderForm'
import { Order } from 'interfaces/order'
import { create, createWithoutCriteria } from '../../../services/ordersService'
import BreadcrumbTitle from '../../atoms/BreadcrumbTitle/BreadcrumbTitle'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import Message, { MessageProps } from '../../atoms/Message/Message'
import { useNavigate } from 'react-router-dom'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import BigButton from 'components/atoms/BigButton/BigButton'
import FileEstructure from 'components/molecules/FileEstructure/FileEstructure'

export default function Orders() {
    const navigate = useNavigate()
    const { apiToken } = useSelector((state: RootState) => state.auth)
    const [message, setMessage] = useState<MessageProps>({
        message: '',
        type: 'default',
    })

    const [data, setData] = useState<Order>({
        file: null,
        template_id: 0,
        description: '',
    })

    const [loading, setLoading] = useState(false)
    const [showButtons, setShowButtons] = useState(true)
    const [stateWithCriteria, setStateWithCriteria] = useState(false)

    const sendOrder = async () => {
        try {
            setLoading(true)
            stateWithCriteria
                ? await create(data, apiToken)
                : await createWithoutCriteria(data, apiToken)
            setData({ file: null, description: '', template_id: 0 })
            setMessage({
                message: 'Carga completada',
                type: 'success',
            })
            setLoading(false)
        } catch (e) {
            setMessage({
                // @ts-expect-error error for any
                message: e.message,
                type: 'error',
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data.file !== null && data.description !== '') {
            sendOrder()
        }
    }, [data])

    const setWithCriteria = (val: boolean) => {
        setShowButtons(false)
        setStateWithCriteria(val)
    }

    return (
        <div>
            {loading && <LoaderTransparent />}
            <BreadcrumbTitle
                title="Cargar nueva orden"
                goToBack={() => navigate('/orders')}
            />
            <div className="mb-4">
                {message.message && <Message {...message} />}
            </div>

            <div className="flex justify-center h-full">
                <div className="flex gap-x-8 w-full p-11">
                    {showButtons ? (
                        <div className="flex gap-x-6 w-full">
                            <BigButton
                                title="Sin Criterios"
                                description=""
                                onClick={() => setWithCriteria(false)}
                                withCriteria={false}
                                disabled={true}
                            />
                            <BigButton
                                title="Con Criterios"
                                description=""
                                onClick={() => setWithCriteria(true)}
                                withCriteria={true}
                                disabled={true}
                            />
                        </div>
                    ) : (
                        <>
                            {data.file?.name ? (
                                <OrderForm
                                    submit={({ description, template_id }) =>
                                        setData({
                                            ...data,
                                            description,
                                            template_id,
                                        })
                                    }
                                    deleteFile={() => {
                                        setData({ ...data, file: null })
                                        setMessage({
                                            message: '',
                                            type: 'default',
                                        })
                                    }}
                                    file={
                                        data.file?.name !== undefined
                                            ? data.file?.name
                                            : 'test'
                                    }
                                    withCriteria={stateWithCriteria}
                                />
                            ) : (
                                <div className="shadow-md h-max bg-white rounded-3xl px-7 py-8 flex w-full flex justify-center mt-6">
                                    <InputFileUpload
                                        accept=".xlsx"
                                        label="Carga desde tu ordenador el archivo de importaciones"
                                        onSubmit={(file) =>
                                            setData({
                                                ...data,
                                                file: file as File,
                                            })
                                        }
                                    >
                                        <div className="text-xs mt-1.5">
                                            <span>
                                                Haga clic{' '}
                                                <a
                                                    className="text-blue-600"
                                                    href={
                                                        stateWithCriteria
                                                            ? require('../../../assets/order_template.xlsx')
                                                            : require('../../../assets/order_without_template.xlsx')
                                                    }
                                                    download={
                                                        stateWithCriteria
                                                            ? 'order_template.xlsx'
                                                            : 'order_without_template.xlsx'
                                                    }
                                                >
                                                    aquí
                                                </a>{' '}
                                                para descargar un archivo de
                                                plantilla.
                                            </span>
                                        </div>
                                    </InputFileUpload>
                                </div>
                            )}
                            {/* Aquí se muestra FileEstructure independientemente de data.file?.name */}
                            <FileEstructure
                                rules={
                                    stateWithCriteria
                                        ? 'withCriteria'
                                        : 'withoutCriteria'
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
