import { ReactComponent as ReactLogo } from '../../../assets/infoIcon.svg'

interface Props {
    onClick: () => void
}

const ButtonOrderDetailInProcessNavigation = ({ onClick }: Props) => {
    return <ReactLogo className="cursor-pointer" onClick={onClick} />
}
export default ButtonOrderDetailInProcessNavigation
