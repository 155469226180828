import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import ListCardChutesOthers from 'components/molecules/ListCardChutesOthers/ListCardChutesOthers'
import ListCardChutesAssigned from 'components/molecules/ListCardsChutesAssigned/ListCardChutesAssigned'
import ListCardChutesAvailables from 'components/molecules/ListCardChutesAvailables/ListCardChutesAvailables'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { showOrder } from 'services/ordersService'
import Message from 'components/atoms/Message/Message'

interface Props {
    orderId: number
}

export default function TemplateShowOrder({ orderId }: Props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [order, setOrder] = useState({
        assigned: {},
        available: [],
        description: '',
        others: {},
        id: 0,
        state: '',
    })

    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await showOrder(orderId)
                setOrder(data)
                setLoading(false)
            } catch (error) {
                setErrorMessage('Ha ocurrido un error intente mas tarde')
                setLoading(false)
            }
        }

        fetchData()
    }, [orderId])

    const formatOrder = (orderAssigned: object) => {
        return Object.entries(orderAssigned).map(([key, values]) => {
            return {
                name: key,
                chutes: values,
            }
        })
    }

    return (
        <div>
            {loading && <LoaderTransparent />}
            <div className="px-10">
                <BreadcrumbTitle
                    title={`Orden # ${orderId}`}
                    subtitle="Visualizar información de ordenes asignadas, disponibles y otros."
                    goToBack={() => navigate(-1)}
                />
                {errorMessage && (
                    <div className="mt-8">
                        <Message message={errorMessage} type={'error'} />
                    </div>
                )}
                <div className="mt-8">
                    <ListCardChutesAvailables
                        name="Disponibles"
                        list={order.available}
                    />
                </div>
                <div className="mt-8">
                    <ListCardChutesAssigned
                        name="Asignados"
                        list={formatOrder(order.assigned)}
                    />
                </div>
                <div className="mt-8">
                    <ListCardChutesOthers name="Otros" list={order.others} />
                </div>
            </div>
        </div>
    )
}
