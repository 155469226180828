import React from 'react'
import { Box } from '../AppBox'

interface Props {
    title: string
    onClose?: () => void
    icon: string
}

export default function CardResumeMessage({ title, onClose, icon }: Props) {
    return (
        <Box>
            <div className="flex justify-between items-center">
                <div className="flex flex-column items-center">
                    <img
                        src={icon}
                        className="img-logo"
                        alt="Upload"
                    />
                    <div className="ml-3 text-ebony-950">{title}</div>
                </div>
                {
                    onClose && <img
                        src={require('../../../assets/Close.png')}
                        className="img-logo cursor-pointer"
                        alt="Upload"
                        onClick={onClose}
                    />
                }

            </div>
        </Box>
    )
}
