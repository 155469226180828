import React, { useEffect, useState } from 'react'
import './styles.css'

interface Props {
    onSubmit: (file: File | null | string) => void
    label?: string,
    accept?: string,
    children?: React.ReactNode,
    base64?: boolean

}

export default function InputFileUpload({onSubmit, accept, label, children, base64 }: Props) {
    const [selectedFile, setSelectedFile] = useState<File | null | string>(null)

    const handleFileChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && base64) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                if (reader.result) {
                    const base64Content = reader.result as string;
                    setSelectedFile(base64Content.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', ''));
                }
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
        } else {
            setSelectedFile(event.target.files ? event.target.files[0] : null)
        }
    };

    useEffect(() => {
        if (selectedFile !== null) onSubmit(selectedFile)
    }, [selectedFile])

    return (
        <div className="">
            <label htmlFor="file-upload" className="flex flex-col items-center">
                <div className="flex flex-column items-center justify-center cursor-pointer">
                    <img
                        src={require('../../../assets/UploadResize.png')}
                        className="img-logo"
                        alt="Upload"
                    />
                </div>
                <div className="mt-6 font-medium cursor-pointer">
                    {label || 'Carga un archivo desde tu ordenador'}
                </div>
                {children}
                {accept && <small className={'mt-6 text-gray-700'}> Archivos aceptados: {accept}</small>}
            </label>

            <div>
                <input
                    id="file-upload"
                    type="file"
                    accept={accept || ''}
                    onChange={handleFileChange}
                    className="hidden"
                ></input>
            </div>
        </div>
    )
}
