import React from 'react';

interface Props {
    children: React.ReactNode;
}

const AppBoxFooter = ({children}: Props) => {
    return (
        <div className='text-center mt-10'>
            {children}
        </div>
    );
};

AppBoxFooter.displayName = 'AppBox.Footer';
export default AppBoxFooter;
