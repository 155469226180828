import React from 'react'
import Layout from '../../../layout/Layout'
import RulesList from 'components/organisms/RulesList/RulesList'
import HeaderPageSection from 'components/molecules/HeaderPageSection/HeaderPageSection'
import AppButton from 'components/atoms/AppButton/AppButton'
import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import { useLocation, useNavigate } from 'react-router-dom'
import Message from '../../atoms/Message/Message'

export default function RulesListPage() {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <Layout>
            <HeaderPageSection>
                <div>
                    <AppButton
                        title="Crear regla"
                        size="sm"
                        state="primary"
                        className="text-sm font-bold"
                        onClick={() => navigate('/rules/create')}
                    />
                    <AppButton
                        title="Cargar nueva orden"
                        size="sm"
                        state="ghost"
                        className="ml-4 text-sm font-bold"
                        onClick={() => navigate('/orders/create')}
                    />
                </div>
            </HeaderPageSection>
            {location.state?.message && (
                <div className="mb-6">
                    <Message
                        message={location.state.message.message}
                        type={location.state.message.type}
                    />
                </div>
            )}

            <div className="ml-9">
                <BreadcrumbTitle
                    title="Reglas"
                    subtitle="Puede tener la capacidad de organizar los reglas y visualizar la informacion que contienen."
                />
            </div>
            <RulesList />
        </Layout>
    )
}
