import AppButton from 'components/atoms/AppButton/AppButton'
import HeaderPageSection from 'components/molecules/HeaderPageSection/HeaderPageSection'
import TemplateRulesList from 'components/organisms/TemplateRulesList/TemplateRulesList'
import Layout from 'layout/Layout'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function ShowTemplatePage() {
    const { templateId } = useParams()
    const templateIdNumber = Number(templateId)
    const navigate = useNavigate()
    return (
        <Layout>
            <HeaderPageSection>
                <div>
                    <AppButton
                        title="Agregar regla"
                        size="sm"
                        state="primary"
                        className="text-sm font-bold"
                        onClick={() => navigate(`/templates/edit/${templateIdNumber}`)}
                    />
                </div>
            </HeaderPageSection>
            <TemplateRulesList template={templateIdNumber} />
        </Layout>
    )
}
