import React from 'react'

export interface MessageProps {
    message: string,
    type: 'default' | 'success' | 'error'
}
const Message = ({message, type}:MessageProps) => {
    let typeClassName = 'default'

    switch (type) {
        case 'success':
            typeClassName = 'bg-green-100 border-green-300 text-green-500'
            break
        case 'error':
            typeClassName = 'bg-red-100 border-red-300 text-red-500'
            break
        default:
            typeClassName = 'bg-gray-100 border-gray-300 '
    }
    return (
        <div className={'mt-5 border-2 rounded-md p-2 ' + typeClassName}>
            <small>{message}</small>
        </div>
    )
}

export default Message
