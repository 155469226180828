import React from 'react'
import AppButton from '../AppButton/AppButton'

interface Props {
    title: string
    onClose: () => void
    handleConfirm: () => void
    isOpen: boolean
}

const AppModal = ({ title, isOpen, onClose, handleConfirm }: Props) => {
    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-10 overflow-y-auto ">
                    <div
                        className="fixed inset-0 w-full h-full bg-black opacity-40 rounded-lg"
                        onClick={onClose}
                    ></div>
                    <div className="flex items-center justify-center min-h-screen px-4 py-8 ">
                        <div className="relative  p-8 mx-auto bg-white rounded-lg shadow-lg">
                            <div className="flex justify-center">
                                <h4 className="text-lg font-bold text-gray-800">
                                    {title}
                                </h4>
                            </div>
                            <div className="mt-5 sm:mt-6 flex justify-center gap-3">
                                <AppButton
                                    title="Cancelar"
                                    onClick={onClose}
                                    state="ghost"
                                    size="sm"
                                    className="font-bold"
                                />
                                <AppButton
                                    title="Confirmar"
                                    onClick={handleConfirm}
                                    size="sm"
                                    state="dark"
                                    className="font-bold"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AppModal
