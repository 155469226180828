import React from 'react'
import AppInput from '../../atoms/AppInput/AppInput'

export interface ValueForm {
    'value': string
}
interface Props {
    label: string,
    placeholder?: string,
    inputPlaceholder?: string,
    onChange: (e:ValueForm) => void,
    isNumber?: boolean
}
const OneValueCriteriaForm = ({label, placeholder, onChange, inputPlaceholder, isNumber}:Props) => {

    return (
        <div>
            <p className='mb-6'>{
                label
            }</p>
            <div className='flex justify-between w-full'>
                <AppInput className={'w-72 mr-6'} number={isNumber} label={placeholder} placeholder={inputPlaceholder} onChange={(e) => onChange({'value': e.target.value})} />
            </div>
        </div>
    )
}

export default OneValueCriteriaForm
