import ItemChute from 'components/atoms/ItemChute/ItemChute'
import React from 'react'
interface Props {
    list: Record<string, number[]> | object
    name?: string | null
}

const ListCardChutesAssigned = ({ list, name }: Props) => {
    return (
        <div className="flex flex-col gap-5 py-5 px-7 bg-white rounded-3xl shadow-md">
            <p className="text-xl font-bold">{name}</p>
            {Object.entries(list).map(([key, values]) => (
                <ItemChute
                    key={key}
                    chutes={values.chutes}
                    title={values.name}
                    theme="active"
                    onClick={() => {
                        console.log('redirect')
                    }}
                />
            ))}
        </div>
    )
}

export default ListCardChutesAssigned
