import React from 'react'
import Layout from '../../../layout/Layout'
import HeaderPageSection from '../../molecules/HeaderPageSection/HeaderPageSection'
import BreadcrumbTitle from '../../atoms/BreadcrumbTitle/BreadcrumbTitle'
import { useNavigate } from 'react-router-dom'
import CreateRuleWizard from '../../organisms/CreateRuleWizard/CreateRuleWizard'
import { resetState } from '../../../store/slices/rule/ruleCreateSlice'
import { AppDispatch } from '../../../store/store'
import { useDispatch } from 'react-redux'

export default function RulesPage() {
    const navigate = useNavigate()
    const dispatch:AppDispatch = useDispatch()
    return (
        <Layout>
            <HeaderPageSection />
            <BreadcrumbTitle
                title="Nueva regla"
                subtitle="Creación de reglas personalizadas con su propia información, como criterios de la regla y el nombre de la regla. "
                goToBack={() => {
                    navigate('/rules')
                    dispatch(resetState())
                }}
            />
            <div className='m-auto w-8/12 lg:w-10/12 mt-6'>
                <CreateRuleWizard onSuccess={() => navigate('/rules', {state:{message: { message: 'Regla creada con éxito', type: 'success' }}})} />
            </div>
        </Layout>

    )
}

