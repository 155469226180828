import React, { useState } from 'react';

interface Props {
    checked: boolean;
    onChange: (value: boolean) => void;
}

const AppSwitch = ({ checked, onChange }:Props) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        onChange(newValue);
    };

    const switchStyles = `relative inline-block w-10 align-middle select-none transition duration-200 ease-in`;
    const switchTrackStyles = `block h-6 w-10 rounded-full `;
    const switchThumbStyles = `absolute inset-y-0.5 left-0 w-5 h-5 bg-white rounded-full shadow transform transition ease-in-out duration-200`;

    const thumbClasses = `transform ${isChecked ? 'translate-x-4' : 'translate-x-0'}`;

    return (
        <div className={switchStyles}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                className="hidden"
                id="switch"
            />
            <label htmlFor="switch" className="cursor-pointer overflow-hidden">
                <span className={`${switchTrackStyles} ${isChecked ? 'bg-blue-500' : 'bg-gray-400'}`}></span>
                <span className={`${switchThumbStyles} ${thumbClasses}`} style={{marginLeft:'2px'}}></span>
            </label>
        </div>
    );
};

export default AppSwitch;
