import AppBox from './AppBox'
import AppBoxFooter from './AppBox.Footer'
import AppBoxHeader from './AppBox.Header'


const Box = Object.assign(AppBox, {
    Footer: AppBoxFooter,
    Header: AppBoxHeader,
});

export { Box };
