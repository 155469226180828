import React from 'react';

interface Props {
    children: React.ReactNode;
}

const AppBoxHeader = ({children}: Props) => {
    return (
        <div className='mb-8'>
            {children}
        </div>
)
    ;
};

AppBoxHeader.displayName = 'AppBox.Header';
export default AppBoxHeader;
