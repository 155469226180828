import React from 'react'
import { ListItem } from '../../../interfaces/order'
import moment from 'moment'
import AppSelect from '../AppSelect/AppSelect'
import {
    orderChangeStates,
    orderStates,
    orderStatesSelect,
} from '../../../utils/constants'

const OrderItem = ({
    children,
    description,
    created_at,
    state,
    onChange,
}: ListItem) => {
    return (
        <div className="border mt-5 flex justify-between items-center px-10 py-5 bg-white rounded-md mx-5">
            <div className="text-left">
                <h2 className="font-bold text-base text-gray-600">
                    {description}
                </h2>
                <h3 className="text-sm text-gray-500">
                    {moment(created_at).format('DD MMM YYYY HH:mm')}
                </h3>
            </div>
            <div className="flex items-center gap-2">
                {state && state !== orderStatesSelect.IN_PROCESS.value ? (
                    <h2 className="text-sm text-gray-500">
                        {orderStatesSelect[state].label}
                    </h2>
                ) : (
                    <AppSelect
                        value={state}
                        options={orderChangeStates}
                        onChange={(state) =>
                            onChange(state.target.value as orderStates)
                        }
                    />
                )}
                {children}
            </div>
        </div>
    )
}

export default OrderItem
