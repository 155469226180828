import React, { useEffect, useState } from 'react'
import RulesFilter from 'components/molecules/RulesFilter/RulesFilter'
import RuleList from 'components/molecules/RuleList/RuleList'
import Pagination from 'components/molecules/Pagination/Pagination'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import { criteriasList, rulesList } from 'services/rulesService'
import { Filter } from 'interfaces/rule'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'

export default function RulesList() {
    const [criterias, setCriterias] = useState([])
    const [loading, setLoading] = useState(false)
    const { apiToken } = useSelector((state: RootState) => state.auth)
    const [filters, setFilters] = useState<Filter>({
        name: '',
        criteria_types: [],
        page: 1,
    })
    const [pagination, setPagination] = useState({
        totalItems: 0,
        page: 1,
        itemsPerPage: 10,
    })
    const [rules, setRules] = useState([])

    const fetchData = async (filters: Filter) => {
        setLoading(true)
        const rules = await rulesList(filters, apiToken)
        setRules(rules.items)
        setPagination({
            page: rules.page,
            totalItems: rules.totalItems,
            itemsPerPage: rules.size,
        })
        setLoading(false)
    }

    const updateFilters = (newFilters: Filter) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...newFilters,
        }))
        fetchData(newFilters)
    }

    const changePage = (page: number) => {
        const newFilters = { ...filters, page }
        setFilters(newFilters)
        setPagination({ ...pagination, page })
        fetchData(newFilters)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const criterias = await criteriasList()
            setCriterias(criterias.data)
            setLoading(false)
        }
        fetchData()
    }, [])

    return (
        <div className="px-9 h-5/6 flex flex-col justify-between">
            {loading && <LoaderTransparent />}
            <div>
                <div className="flex justify-between">
                    {criterias.length > 0 && (
                        <RulesFilter
                         criterias={criterias}
                            onChange={(f) => updateFilters(f)}
                        />
                    )}
                </div>
                {rules.length > 0 ? (
                    <RuleList rules={rules} criterias_selected={criterias} />
                ) : (
                    <div className="flex justify-center p-4 text-sm mt-9">
                        no hay reglas para mostrar
                    </div>
                )}
            </div>
            <div className="flex justify-center mt-10">
                <Pagination
                    totalPages={Math.ceil(
                        pagination.totalItems / pagination.itemsPerPage
                    )}
                    pagAct={pagination.page}
                    currentPage={pagination.page}
                    onPageChange={changePage}
                />
            </div>
        </div>
    )
}
