import React from 'react'
import NumberAndTotalChutes from '../NumberAndTotalChutes/NumberAndTotalChutes'
import './styles.css'
import { Chute } from 'interfaces/order'

interface Props {
    name: string
    lastUpdate: string | null
    total: string
    chutes: Chute[],
    totalPackages: number
}

const RuleDetailAndChuteCard = ({
    name,
    lastUpdate,
    total,
    chutes,
    totalPackages,

}: Props) => {
    const renameProperties = (obj: Chute) => {
        return {
            chuteNumber: obj.chute_number,
            totalPackages: obj.total_packages,
        }
    }

    return (
        <div className="bg-white rounded-lg border card py-5 px-7">
            <div className="flex items-baseline justify-between">
                <p className="text-xs">{name}</p>
                <p>
                    <span className="text-10 font-light">
                        Última actualización
                    </span>{' '}
                    <span className="text-xs font-bold text-slate-600">
                        {lastUpdate}
                    </span>
                </p>
                <p className="text-10 font-light">
                    {"Total "}
                    <span className="text-xs font-bold text-slate-600">
                        {total} / {totalPackages}
                    </span>
                </p>
            </div>
            <div className="flex flex-wrap space-between m-2 ml-4 mb-0 gap-10">
                {chutes.map((item) => {
                    const transformedChute = renameProperties(item)
                    return <NumberAndTotalChutes chute={transformedChute} />
                })}
            </div>
        </div>
    )
}

export default RuleDetailAndChuteCard
