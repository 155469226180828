import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchData } from './'
import { UserLoginResponse } from '../../../services/interfaces/auth'
export interface AuthState {
    errorMessage?: string | null
    removeError?: () => void
    apiToken:string,
    isLoading: boolean
}

const initialState: AuthState = {
    apiToken: '',
    isLoading: true
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        isLoading: (state) => {
            state.isLoading = true
        },
        setApiToken: (state, action:PayloadAction<string>) => {
            state.apiToken = action.payload
            state.isLoading = false
        },
        clearToken: (state) => {
            state.apiToken = ''
            state.isLoading= false
            localStorage.removeItem('token')
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchData.fulfilled, (state, action:PayloadAction<UserLoginResponse>) => {
                state.isLoading = false;
                state.apiToken = action.payload.access_token;
                state.errorMessage = ''
                localStorage.setItem('token', action.payload.access_token)
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                state.apiToken = '';
                state.errorMessage = action.error.message
                localStorage.removeItem('token')
            });
    },
})
export const { isLoading, clearToken, setApiToken } = authSlice.actions
