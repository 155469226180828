import { UserLoginError, UserLoginResponse, OAuth, UserLogin } from './interfaces/auth'
import {trackingAuth} from '../config/api/tracking'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchToken = async (user: UserLogin) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAuth.post<UserLoginResponse>(
            '/token',
            { ...OAuth, ...user },
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as UserLoginError
    }
}
