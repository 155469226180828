import React, { useState } from 'react'
import './styles.css'
import { PostalStructure } from 'interfaces/rule'

interface Props {
    list: PostalStructure[]
    title: string
    onClick: (item: PostalStructure) => void
}

const SelectList = ({ list, title, onClick }: Props) => {
    const [activeItems, setActiveItems] = useState<{ [key: string]: boolean }>(
        {}
    )
    const handleClick = (item: PostalStructure) => {
        setActiveItems((prevState) => ({
            ...prevState,
            [item.name]: !prevState[item.name],
        }))
        onClick(item)
    }

    return (
        <div className="mt-7">
            <label>{title}</label>
            <div className="border-gray-200 rounded border-2 bg-white p-5 list">
                <p className="font-extralight">Seleccionar</p>
                {list.map((item) => {
                    const isActive = activeItems[item.name] || false
                    return (
                        <div
                            className={`item p-2 ${
                                isActive ? 'bg-slate-200' : ''
                            }`}
                            key={item.name}
                            onClick={() => handleClick(item)}
                        >
                            <p>{item.name}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectList
