import React, { useEffect, useState } from 'react'
import { Box } from '../../atoms/AppBox'
import { Chute } from 'interfaces/templates'
interface Props {
    onChange: (chutes: number[]) => void
    chutes: Chute[] | null
}

interface ChuteAux {
    chute: number
    type: string
    selected: boolean
}
const SelectChute = ({ chutes, onChange }: Props) => {
    const [chutesAux, setChutesAux] = useState<ChuteAux[]>([])
    useEffect(() => {
        if (chutes && chutes.length > 0) {
            const newChutes = chutes.map((item) => ({
                chute: item.chute,
                type: item.type,
                selected: false,
            }))

            setChutesAux(newChutes)
        }
    }, [chutes])

    const handleClick = (number: number) => {
        const newChutes = chutesAux
        const findChutes = newChutes.findIndex(
            (chute) => chute.chute === number
        )
        if (findChutes !== -1) {
            newChutes[findChutes] = {
                ...newChutes[findChutes],
                selected: !newChutes[findChutes].selected,
            }
        }
        setChutesAux([...newChutes])
        const filterNumber = chutesAux.filter((chute) => chute.selected)
        const numberChutes = filterNumber.map((chute) => chute.chute)
        onChange(numberChutes)
    }

    return (
        <Box>
            <h2 className="font-bold mb-5">Chutes</h2>
            <div className="flex flex-wrap gap-3 justify-center">
                {chutesAux?.map((item) => {
                    if (
                        item.type === 'not_available' ||
                        item.type === 'assigned'
                    ) {
                        return (
                            <button
                                key={item.chute}
                                className={`border rounded-md flex justify-center items-center w-[40px] h-[40px] cursor-not-allowed	 ${
                                    item.type === 'assigned'
                                        ? 'bg-yellow-100 border-yellow-600 text-yellow-600'
                                        : ''
                                } ${
                                    item.type === 'not_available'
                                        ? 'bg-gray-300 border-gray-300 text-gray-400 bg-opacity-50'
                                        : ''
                                }`}
                            >
                                {item.chute}
                            </button>
                        )
                    } else {
                        return (
                            <button
                                key={item.chute}
                                onClick={() => handleClick(item.chute)}
                                className={`border rounded-md flex justify-center items-center w-[40px] h-[40px] ${
                                    item.selected
                                        ? 'bg-ebony-300 border-ebony-500 text-ebony-500'
                                        : ''
                                }`}
                            >
                                {item.chute}
                            </button>
                        )
                    }
                })}
            </div>
            <Box.Footer>
                <div className="flex gap-5 mt-10 justify-center">
                    <div className="flex gap-3 items-center">
                        <div className=" w-[20px] h-[20px] border rounded-md bg-ebony-300 border-ebony-500"></div>
                        <h3>Seleccionados</h3>
                    </div>
                    <div className="flex gap-3 items-center">
                        <div className=" w-[20px] h-[20px] border rounded-md bg-yellow-100 border-yellow-300 text-yellow-300"></div>
                        <h3>Asignados</h3>
                    </div>
                    <div className="flex gap-3 items-center">
                        <div className=" w-[20px] h-[20px] border rounded-md "></div>
                        <h3>Sin seleccionar</h3>
                    </div>
                </div>
            </Box.Footer>
        </Box>
    )
}
export default SelectChute
