import React from 'react'
import './styles.css'

interface Props extends React.ComponentPropsWithoutRef<"input">{
    label?: string
    number?: boolean
    disabled?: boolean
}
const AppInput = ({label, disabled = false, className, number, ...rest}:Props) => {
    return (
        <label className={'flex flex-col text-left text-shark-800'}>{label}
            <input type={number ? 'number' : 'text'} className={`${label ? 'mt-1' : ''} input ${disabled ? 'disabled' : ''}` + className} disabled={disabled} {...rest} />
        </label>
    )
}


export default AppInput

