import React, { ChangeEvent } from 'react';
import AppInput from '../../atoms/AppInput/AppInput'
import AppButton from 'components/atoms/AppButton/AppButton';
import { Box } from '../../atoms/AppBox'

interface Props {
    onChange: (value: string) => void;
    onClick: () => void;
    title: string;
    subtitle?: string;
    label?: string;
    disabled?: boolean
}

const CardInputRuleForm = ({ onChange, title, subtitle, label, onClick, disabled }: Props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <Box>
            <Box.Header>
                {title && <h2 className='font-bold'>{title}</h2>}
                {subtitle && <p>{subtitle}</p>}
            </Box.Header>
            <AppInput placeholder={label} onChange={handleChange} />
            <Box.Footer>
                <AppButton title='Continuar' state='dark' disabled={disabled} onClick={onClick} />
            </Box.Footer>
        </Box>
    );
};

export default CardInputRuleForm;
