import React from 'react'

export const LoaderTransparent = () => {
    return (
        <div className="fixed inset-y-0	inset-x-0 flex items-center justify-center bg-gray-200/75">
            <div className="flex items-center justify-center flex-col bg-white p-8 rounded-xl max-w-full max-h-full">
                <img
                    src={require('../../../assets/Icon.png')}
                    className="img-logo"
                    alt="Logo"
                />
                <p className="mt-2 text-ebony-950/75">Cargando...</p>
            </div>
        </div>
    )
}
