import React from 'react'
import { withCriteria, withoutCriteria } from 'utils/fileEstructure'

interface Props {
    rules: string
}

interface Rule {
    title: string
    value: string
}

const FileEstructure = ({ rules }: Props) => {
    const rulesMap = {
        withCriteria,
        withoutCriteria,
    }

    const rulesTest = rulesMap[rules as keyof typeof rulesMap]

    const rulesItems = rulesTest.map((rule: Rule) => (
        <div className="flex flex-column mt-4">
            <div className="w-2/5">
                <p className="text-xs font-normal">{rule.title}</p>
            </div>
            <div className="w-3/5">
                <p className="text-xs font-normal">{rule.value}</p>
            </div>
        </div>
    ))

    return (
        <div
            className={
                'shadow-md bg-white rounded-3xl py-7 px-11 w-full mt-6 h-max w-full'
            }
        >
            <p className="text-base font-medium leading-5">
                Estructura de archivos
            </p>
            <div className="flex flex-column mt-4">
                <div className="w-2/5">
                    <p className="text-xs font-semibold">Nombre de archivo</p>
                </div>
                <div className="w-3/5">
                    <p className="text-xs font-semibold">Regla de validacion</p>
                </div>
            </div>
            {rulesItems}
        </div>
    )
}

export default FileEstructure
