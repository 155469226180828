import React, { useState } from 'react'
import AppInput from '../../../atoms/AppInput/AppInput'

export interface WeightForm {
    'first_value': string,
    'second_value': string,
    'operator': string,
}
interface Props {
    data?: WeightForm,
    onChange: (e:WeightForm) => void,
    label?: string,
    placeholder?: string[],
    inputPlaceholders?: string[]
}
const RangeCriteriaForm = ({onChange, placeholder, inputPlaceholders, label}:Props) => {
    const [formData, setData] = useState<WeightForm>({
        first_value: '',
        second_value: '',
        operator:''
    })

    const handleOnChange = (input:WeightForm) => {
        let operator = 'between'
        if(input.first_value !== '' && input.second_value === '')
            operator = '>='
        if(input.first_value === '' && input.second_value !== '')
            operator = '<='
        if(input.first_value === '' && input.second_value === '')
            operator = ''

        setData({ ...formData, ...input, operator })
        onChange({ ...formData, ...input, operator })
    }

    return (
        <div className='w-full'>
            <p className='mb-6'>{
                label
            }</p>
            <div className='flex w-full'>
                <AppInput className={'w-12  lg:w-24 2xl:w-32 mr-2'} value={formData.first_value} number step="0.10" placeholder={inputPlaceholders && inputPlaceholders[0]} label={placeholder && placeholder[0]} onChange={(e) => handleOnChange({
                    ...formData,
                    first_value: e.target.value
                })} />
                <AppInput className={'w-12 lg:w-24 2xl:w-32 '} number value={formData.second_value} step="0.10" placeholder={inputPlaceholders && inputPlaceholders[1]} label={placeholder && placeholder[1]} onChange={(e) => handleOnChange({
                    ...formData,
                    second_value: e.target.value
                })}/>
            </div>
        </div>
    )
}

export default RangeCriteriaForm
