import React, { ChangeEvent, useState } from 'react';
import AppButton from 'components/atoms/AppButton/AppButton';
import AppSelect from 'components/atoms/AppSelect/AppSelect';
import { TemplateRule } from '../../../interfaces/templates'
import { Box } from '../../atoms/AppBox'

interface Props {
    onClick: (value: number) => void;
    title: string;
    subtitle?: string;
    label?: string;
    items: TemplateRule[]
}



const CardSelectRuleForm = ({ title, subtitle, label = 'Nombre de la Regla', items, onClick }: Props) => {
    const [ruleName, setRuleName] = useState<number>(0)
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setRuleName(parseInt(event.target.value))
        // onChange(parseInt(event.target.value));
    };

    const convertArray = () => {
        return items.map(item => ({
            value: item.id,
            label: item.name,
        }));
    };

    return (
        <Box >
            <Box.Header>
                {title && <h2 className='font-bold'>{title}</h2>}
                {subtitle && <p>{subtitle}</p>}
            </Box.Header>
            <AppSelect placeholder={label} onChange={handleChange} options={convertArray()} />
            <Box.Footer>
                <AppButton title='Continuar' state='dark' onClick={() => {
                    onClick(ruleName)
                }} />
            </Box.Footer>
        </Box>
    );
};

export default CardSelectRuleForm;