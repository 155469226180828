import React, { useEffect, useState } from 'react'
// import AppInput from '../../../atoms/AppInput/AppInput'
import { CriteriaData } from '../../../../interfaces/rule'
import RangeCriteriaForm from './RangeCriteriaForm'

// interface MeasurementItemProps {
//     label: string,
//     data: CriteriaData | undefined,
//     onChange: (e:CriteriaData) => void
// }
interface Props {
    data?: CriteriaData[],
    onChange: (e:CriteriaData[]) => void
}

const initialValue = {
    value: '',
    operator: '',
    second_value: '',
    first_value: ''
}
const MeasurementCriteriaForm = ({ onChange }: Props) => {
    const [widthForm, setWidthForm] = useState<CriteriaData>(initialValue)
    const [heightForm, setHeightForm] = useState<CriteriaData>(initialValue)
    const [lengthForm, setLengthForm] = useState<CriteriaData>(initialValue)

    useEffect(() => {
        onChange([{ ...widthForm, value:'width' }, { ...heightForm, value:'height' }, { ...lengthForm, value:'length' }])

    }, [widthForm, heightForm, lengthForm])


    return (
        <div className='w-full'>
            <p className="mb-6">Ingresa medidas</p>
            <div className="flex w-full">
                <div className='mr-6'>
                    <RangeCriteriaForm label='Ancho' data={{
                        first_value: widthForm?.first_value || '',
                        second_value: widthForm?.second_value || '',
                        operator: widthForm?.operator || ''
                    }} inputPlaceholders={['0.1', '0.2']} onChange={(v) => setWidthForm({ ...v, value: 'width' })} />
                </div>
                <div className=' mr-6'>
                    <RangeCriteriaForm label='Altura' data={{
                        first_value: heightForm?.first_value || '',
                        second_value: heightForm?.second_value || '',
                        operator: heightForm?.operator || ''
                    }} inputPlaceholders={['0.1', '0.2']} onChange={(v) => setHeightForm({ ...v, value: 'height' })} />
                </div>
                <div>
                    <RangeCriteriaForm label='Largo' data={{
                        first_value: lengthForm?.first_value || '',
                        second_value: lengthForm?.second_value || '',
                        operator: lengthForm?.operator || ''
                    }} inputPlaceholders={['0.1', '0.2']} onChange={(v) => setLengthForm({ ...v, value: 'length' })} />
                </div>
            </div>
        </div>
    )
}

export default MeasurementCriteriaForm
