import React from 'react'
import './styles.css'

interface OptionProps extends React.ComponentPropsWithoutRef<'option'> {
    value: string | number
    label: string
}
interface Props extends React.ComponentPropsWithoutRef<'select'> {
    options: OptionProps[]
    label?: string
    placeholder?: string
}

const AppOption = (props: OptionProps) => (
    <option {...props}>{props.label}</option>
)
const AppSelect = ({ label, placeholder = '', options, ...rest }: Props) => {
    return (
        <label className={'flex flex-col text-left'}>
            {label}
            <div className={'select'}>
                <select {...rest} className="w-full outline-none">
                    {placeholder !== '' && (
                        <AppOption
                            value={''}
                            label={placeholder}
                            disabled
                            selected
                        />
                    )}
                    {options.map((o, index) => (
                        <AppOption
                            key={index}
                            value={o.value}
                            label={o.label}
                            selected={o.value === 'FINISHED'}
                        />
                    ))}
                </select>
            </div>
        </label>
    )
}

export default AppSelect
