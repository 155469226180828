import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../../layout/Layout'
import OrdersList from 'components/organisms/OrdersList/OrdersList'
import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import HeaderPageSection from 'components/molecules/HeaderPageSection/HeaderPageSection'
import AppButton from 'components/atoms/AppButton/AppButton'
import { useNavigate } from 'react-router-dom'
import { ordersList } from 'services/ordersService'
import { AppDispatch, RootState } from '../../../store/store'
import { Outlet } from 'react-router-dom'
import { OrdersFilters } from 'interfaces/order'
import Pagination from 'components/molecules/Pagination/Pagination'
import SearchFilter from 'components/molecules/SearchFilter/SearchFilter'
import { setState } from '../../../store/slices/order/orderSlice'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'

interface Filter {
    search: string | null
    states: string[]
    date: {
        start: Date | null
        end: Date | null
    }
}

const OrdersListPage = () => {
    const { apiToken } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch()
    const { items } = useSelector((state: RootState) => state.order)
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({
        totalItems: 10,
        page: 1,
        itemsPerPage: 10,
    })
    const [filtersData, setFiltersData] = useState({
        created_before_than: null as Date | null,
        created_after_than: null as Date | null,
        states: '',
        description: '' as string | null,
    })

    const sendOrder = async (data: OrdersFilters, apiToken: string) => {
        setLoading(true)
        const result = await ordersList(data, apiToken)
        dispatch(setState({ ...result, loading: false }))
        setPagination({
            page: result.page,
            totalItems: result.totalItems,
            itemsPerPage: result.size,
        })
        setLoading(false)
    }

    const changePage = (page: number) => {
        setPagination((prevState) => ({
            ...prevState,
            page: page,
        }))
        sendOrder(
            {
                ...filtersData,
                page: page,
            },
            apiToken
        )
    }

    useEffect(() => {
        async function updateOrders() {
            sendOrder(
                {
                    created_before_than: null,
                    created_after_than: null,
                    states: '',
                    description: '',
                    page: pagination.page,
                },
                apiToken
            )
        }
        updateOrders()
    }, [apiToken])

    const updateFilters = async (filters: Filter) => {
      const data = {
            created_before_than: filters.date.end,
            created_after_than: filters.date.start,
            states: filters.states.join(','),
            description: filters.search || ''
        }
      if(JSON.stringify(data) !== JSON.stringify(filtersData)  ) {
        sendOrder({ ...data, page: 1 }, apiToken)
      }
      setFiltersData(data)
    }

    return (
        <Layout>
            {loading && <LoaderTransparent />}
            <Outlet />
            {loading && <LoaderTransparent />}
            <HeaderPageSection>
                <AppButton
                    className="font-bold text-sm"
                    title="Cargar nueva orden"
                    size="sm"
                    state="ghost"
                    onClick={() => navigate('/orders/create')}
                />
            </HeaderPageSection>
            <div className="px-5">
                <BreadcrumbTitle
                    title="Órdenes"
                    subtitle="Visualizar información de órdenes y filtrar busquedas."
                />
            </div>
            <SearchFilter onChange={(f) => updateFilters(f)} />
            {items && (
                <div style={{ height: '500px', overflowY: 'auto' }}>
                    {' '}
                    {items.length > 0 ? (
                        <OrdersList orders={items} />
                    ) : (
                        <div className="flex justify-center p-4 text-sm">
                            no hay órdenes para mostrar
                        </div>
                    )}
                </div>
            )}
            <div className="flex justify-center mt-10">
                <Pagination
                    totalPages={Math.ceil(
                        pagination.totalItems / pagination.itemsPerPage
                    )}
                    pagAct={pagination.page}
                    currentPage={pagination.page}
                    onPageChange={(page) => changePage(page)}
                />
            </div>
        </Layout>
    )
}

export default OrdersListPage
