import React from 'react'
import Layout from '../../../layout/Layout'
import HeaderPageSection from '../../molecules/HeaderPageSection/HeaderPageSection'
import TemplatesList from 'components/organisms/TemplatesList/TemplatesList'
import { useNavigate } from 'react-router-dom'
import AppButton from 'components/atoms/AppButton/AppButton'

export default function TemplatesPage() {
    const navigate = useNavigate()
    return (
        <Layout>
            <HeaderPageSection>
                <div>
                    <AppButton
                        title="Crear plantilla"
                        size="sm"
                        state="primary"
                        className="text-sm font-bold"
                        onClick={() => navigate('/templates/create')}
                    />
                </div>
            </HeaderPageSection>
            <TemplatesList />
        </Layout>
    )
}
