import React from 'react'
import LoginPage from '../components/pages/LoginPage/LoginPage'
import { Navigate, Route, Routes } from 'react-router-dom'

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="login" element={ <LoginPage /> } />

            <Route path='/*' element={ <Navigate to="/login" /> } />
        </Routes>
    )
}

export default AuthRoutes
