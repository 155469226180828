import React, { useEffect, useState } from 'react'
import CriteriaSelection from '../../molecules/CriteriaSelection/CriteriaSelection'
import CardInputRuleForm from '../../molecules/CardInputRuleForm/CardInputRuleForm'
import { createRule, criteriasList } from '../../../services/rulesService'
import { CriteriaElement, CriteriaList } from '../../../interfaces/rule'
import CriteriaConfigForm from '../CriteriaConfigForm/CriteriaConfigForm'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store/store'
import {
    setCriteriaSelection,
    setName,
    setCriterias as setCriteriasState,
    resetState,
} from '../../../store/slices/rule/ruleCreateSlice'
import Message from '../../atoms/Message/Message'

type steps = 1 | 2 | 3
interface Props {
    onSuccess: () => void
}
const CreateRuleWizard = ({ onSuccess }: Props) => {
    const {
        name: ruleName,
        criteriaSelection,
        description,
    } = useSelector((state: RootState) => state.ruleCreate)
    const dispatch: AppDispatch = useDispatch()
    const [step, setStep] = useState<steps>(1)
    const [criterias, setCriterias] = useState<CriteriaList[]>([])
    const [errMessage, setErrMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const criterias = await criteriasList()
            setCriterias(criterias.data)
        }
        fetchData()
    }, [])

    const handleCriteriaSelection = (selection: CriteriaList[]) => {
        dispatch(setCriteriaSelection(selection))
        setStep(3)
    }

    const handleCreateRule = (c: CriteriaElement[]) => {
        dispatch(setCriteriasState(c))
        setLoading(true)
        createRule({
            name: ruleName,
            description,
            criterias: c,
        })
            .then(() => {
                onSuccess()
                dispatch(resetState())
            })
            .catch((e) => {
                setErrMessage(e.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <div className="mb-6">
                {errMessage && <Message message={errMessage} type={'error'} />}
            </div>

            {step === 1 && (
                <CardInputRuleForm
                    onChange={(name) => dispatch(setName(name))}
                    onClick={() => {
                        setStep(2)
                    }}
                    disabled={ruleName === ''}
                    title={'Nombre de la Regla'}
                    subtitle="Ingrese el nombre para crear la nueva regla"
                />
            )}
            {step === 2 && (
                <CriteriaSelection
                    onChange={(selection) =>
                        dispatch(setCriteriaSelection(selection))
                    }
                    criteria={criterias}
                    criteriaSelection={criteriaSelection}
                    disabled={criteriaSelection.length === 0}
                    onSubmit={handleCriteriaSelection}
                />
            )}
            {step === 3 && (
                <CriteriaConfigForm
                    criterias={criteriaSelection}
                    onClick={handleCreateRule}
                    onBack={() => setStep(2)}
                    loading={loading}
                />
            )}
        </div>
    )
}

export default CreateRuleWizard
