import React, { ReactNode } from 'react'

interface Props {
    children: ReactNode
}
const Content = ({ children }: Props) => {
    return <div className="w-10/12">{children}</div>
}

export default Content
