// import AppButton from 'components/atoms/AppButton/AppButton'
import React from 'react'
interface Props {
    title: string
    chutes: number[]
    theme: string
    onClick: () => void
}

const ItemChute = ({ title, chutes, theme }: Props) => {
    const getChutes = () => {
        return (
            <>
                <div className="flex items-center gap-3">
                    {chutes.map((item, index) => (
                        <h1
                            key={index}
                            className={`w-[30px] h-[30px] flex justify-center items-center rounded-full ${
                                theme === 'active'
                                    ? 'bg-sky-200'
                                    : 'bg-gray-200'
                            }`}
                        >
                            {item}
                        </h1>
                    ))}
                </div>
            </>
        )
    }
    return (
        <div className="border rounded-md pl-12 pr-5 py-5 flex justify-between items-center bg-white">
            <h2 className="text-sm uppercase">{title}</h2>
            {getChutes()}
        </div>
    )
}

export default ItemChute
