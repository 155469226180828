import { tracking } from '../../config'

export interface Auth {
    access_token: string | undefined
    token_type: string | undefined
}

export const OAuth = {
    client_id: tracking.clientId,
    client_secret: tracking.clientSecret,
}

// LOGIN
export interface UserLogin {
    username: string
    password: string
    remember?: boolean
}

export interface UserLoginResponse {
    access_token: string
}

export interface UserLoginError {
    error: string
    message: string
}
