import { trackingAxios } from '../config/api/tracking'
import { AssignTemplate } from '../interfaces/templates'
export interface Template {
    id:number,
    name: string
    description: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const create = async (template: Template) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.post(
            'templates',
            { name: template.name },
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getTemplates = async (page: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get(`templates?page=${page}`, {
            headers,
        })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getTemplatesRules = async () => {
  try {
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    }
    const response = await trackingAxios.get(`templates/groups`, {
      headers,
    })
    return response.data
  } catch (e) {
    // Sentry.Native.captureException(e)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw e.response.data
  }
}

export const getTemplateDetail = async (id: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get(`templates/${id}`, { headers })
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const getTemplateRules = async (template: number) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.get(
            `templates/${template}/groups`,
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}

export const addChute = async (template: string, assign: AssignTemplate) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
        }
        const response = await trackingAxios.post(
            `templates/${template}/associate`,
            assign,
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data
    }
}
