import AppButton from 'components/atoms/AppButton/AppButton'
import BreadcrumbTitle from 'components/atoms/BreadcrumbTitle/BreadcrumbTitle'
import HeaderPageSection from 'components/molecules/HeaderPageSection/HeaderPageSection'
import Layout from 'layout/Layout'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { showOrderDetails, getOrderReport } from 'services/ordersService'
import ShowOrderDetails from 'components/organisms/ShowOrderDetails/ShowOrderDetails'
import { LoaderTransparent } from 'components/molecules/LoaderTransparent/LoaderTransparent'
import Message, { MessageProps } from 'components/atoms/Message/Message'

export default function ShowOrderDetailsPage() {
    const navigate = useNavigate()
    const { orderId } = useParams()
    const orderIdNumber = Number(orderId)
    const [loading, setLoading] = useState(false)
    const [order, setOrder] = useState({
        createdAt: '',
        description: '',
        groups: {},
        id: 0,
        state: '',
        total: 0,
        totalProcessed: 0,
        totalWithoutProcessed: 0,
    })
    const [message, setMessage] = useState<MessageProps>({
        message: '',
        type: 'default',
      })
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await showOrderDetails(orderIdNumber)
                setOrder(data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    const sendReport = async () => {
        try {
            setLoading(true)
            const data = await getOrderReport(orderIdNumber)
            if (data.status === 200) {
                setMessage({message: 'Se ha procesado correctamente, se estará enviado un correo electrónico con el reporte generado', type: 'success'})
            } else {
                setMessage({message: 'Ocurrió un error con la generación del reporte, intente nuevamente', type: 'error'})
            }

            setLoading(false)
        } catch (error) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setMessage({message: error.data.message, type: 'error'})
            setLoading(false)
        }
        
    }

    return (
        <Layout>
            {loading && <LoaderTransparent />}
            <HeaderPageSection>
                <div>
                    <AppButton
                        title="Exportar"
                        size="sm"
                        state="primary"
                        className="text-sm font-bold"
                        onClick={() => sendReport()}
                    />
                    <AppButton
                        title="Cargar nueva orden"
                        size="sm"
                        state="ghost"
                        className="ml-4 text-sm font-bold"
                        onClick={() => navigate('/orders/create')}
                    />
                </div>
            </HeaderPageSection>
            <div className="ml-6">
                <BreadcrumbTitle
                    title={`${order.description}: #${orderIdNumber}`}
                    subtitle="Detalle de la Orden"
                    goToBack={() => navigate('/orders')}
                />
                {message.message && <Message
                    message={message.message}
                    type={message.type}
                />}
                
            </div>
            <ShowOrderDetails order={order} />
        </Layout>
    )
}
