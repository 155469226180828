import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './slices/auth'
import { userSlice } from './slices/user'
import { orderSlice } from './slices/order/orderSlice'
import { ruleCreateSlice } from './slices/rule/ruleCreateSlice'
export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        user: userSlice.reducer,
        order: orderSlice.reducer,
        ruleCreate: ruleCreateSlice.reducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
