import React, { useEffect, useState } from 'react'
import AppButton from '../../atoms/AppButton/AppButton'
import { CriteriaList } from '../../../interfaces/rule'

interface Props {
    criteria: CriteriaList[]
    onSubmit: (selected: CriteriaList[]) => void
    onChange: (selected: CriteriaList[]) => void
    criteriaSelection: CriteriaList[]
    disabled: boolean
}

const CriteriaSelection = ({
    criteria,
    onSubmit,
    criteriaSelection,
    disabled,
    onChange,
}: Props) => {
    const [selectedIds, setSelectedIds] = useState<string[]>([])

    const toggleSelection = (id: string) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((itemId) => itemId !== id))
        } else {
            setSelectedIds([...selectedIds, id])
        }
    }

    useEffect(() => {
        if (criteriaSelection.length > 0) {
            const selection = criteriaSelection.map((item) => item.key)
            setSelectedIds(selection)
        }
    }, [])

    useEffect(() => {
        onChange(criteria.filter((c) => selectedIds.includes(c.key)))
    }, [selectedIds])

    return (
        <div className={'shadow-md bg-white rounded-3xl px-7 py-8 w-full mt-6'}>
            <div className="mb-8">
                <p className="text-xl font-bold mb-3">Criterios</p>
                <p>Selecciona algunas de los criterios para editar la regla</p>
            </div>
            <div className="flex flex-wrap gap-5 mb-10">
                {criteria.map((c, index) => (
                    <AppButton
                        key={index}
                        title={c.name}
                        rounded
                        onClick={() => toggleSelection(c.key)}
                        state={selectedIds.includes(c.key) ? 'info' : 'default'}
                    />
                ))}
            </div>
            <div className="text-center">
                <AppButton
                    title="Continuar"
                    state="dark"
                    disabled={disabled}
                    onClick={() =>
                        onSubmit(
                            criteria.filter((c) => selectedIds.includes(c.key))
                        )
                    }
                />
            </div>
        </div>
    )
}

export default CriteriaSelection
