import React from 'react'

interface Props {
    currentPage: number
    pagAct: number
    totalPages: number
    onPageChange: (page: number) => void
}

const Pagination = ({
    currentPage,
    pagAct,
    totalPages,
    onPageChange,
}: Props) => {
    const geCount = () => {
        const elements = []
        for (let index = 1; index < totalPages + 1; index++) {
            elements.push(
                <li key={index} onClick={() => onPageChange(index)}>
                    <a
                        href="#"
                        className={` ${
                            pagAct == index
                                ? 'z-10 flex items-center justify-center px-4 h-10 leading-tight text-white border border-gray-700 bg-gray-700 hover:bg-gray-700 hover:text-white dark:border-gray-700 dark:bg-white-700 dark:text-white'
                                : 'flex items-center justify-center px-4 h-10 leading-tight bg-white border border-gray-700 hover:bg-gray-700 hover:text-white dark:bg-white-800 dark:border-gray-700 dark:text-gray-700 dark:hover:bg-white-700 dark:hover:text-white'
                        } `}
                    >
                        {index}
                    </a>
                </li>
            )
        }
        return elements
    }
    return (
        <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
                {currentPage > 1 && (
                    <li onClick={() => onPageChange(currentPage - 1)}>
                        <a
                            href="#"
                            className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-700 bg-white border border-e-0 border-gray-700 rounded-s-lg hover:bg-gray-700 hover:text-white dark:bg-white-800 dark:border-gray-700 dark:text-gray-700 dark:hover:bg-white-700 dark:hover:text-white"
                        >
                            {'<<'}
                        </a>
                    </li>
                )}

                {geCount()}
                {currentPage < totalPages && (
                    <li onClick={() => onPageChange(currentPage + 1)}>
                        <a
                            href="#"
                            className="flex items-center justify-center px-4 h-10 leading-tight text-gray-700 bg-white border border-gray-700 rounded-e-lg hover:bg-gray-700 hover:text-white dark:bg-white-800 dark:border-gray-700 dark:text-gray-700 dark:hover:bg-white-700 dark:hover:text-white"
                        >
                            {'>>'}
                        </a>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default Pagination
