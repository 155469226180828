import AppRouter from './routes/AppRouter'

function App() {
    return (
        <div className="App bg-gray-50" >
            <AppRouter />
        </div>
    )
}

export default App
