import React, { useState, useEffect } from 'react'
import AppDate from '../../atoms/AppDate/AppDate'
import AppInput from '../../atoms/AppInput/AppInput'
import AppButton from 'components/atoms/AppButton/AppButton'
import './styles.css'

interface Props {
    onChange: (data: Filter) => void
}

interface Filter {
    search: string | null
    states: Array<string>
    date: {
        start: Date | null
        end: Date | null
    }
}

const SearchFilter = ({ onChange }: Props) => {
    const [filter, setFilter] = useState<Filter>({
        search: null,
        states: [],
        date: {
            start: null,
            end: null,
        },
    })

    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const target = e.target as HTMLInputElement
            setFilter((prevFilter) => ({
                ...prevFilter,
                search: target.value,
            }))
        }
    }

    const handleDateChange = (newDateRange: Partial<Filter['date']>) => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            date: {
                ...prevFilter.date,
                ...newDateRange,
            },
        }))
    }
    const handleState = (value: 'FINISHED' | 'CANCELED' | 'IN_PROCESS') => {
        setFilter((prevFilter) => {
            const currentStates = prevFilter.states
            // Verifica si el estado ya existe en el array
            const exists = currentStates.includes(value)
            // Si existe, crea un nuevo array sin ese estado
            if (exists) {
                return {
                    ...prevFilter,
                    states: currentStates.filter((state) => state !== value),
                }
            } else {
                // Si no existe, añade el estado al array
                return {
                    ...prevFilter,
                    states: [...currentStates, value],
                }
            }
        })
    }

    // Llamar a onChange con el filtro actualizado cada vez que cambie
    useEffect(() => {
        onChange(filter)
    }, [filter])

    return (
        <div className=" flex justify-between gap-5 p-5">
            <div className="w-1/2">
                <AppInput
                    placeholder="Buscar..."
                    name="search"
                    onKeyDown={handleSearch}
                />
            </div>
            <div className=" flex justify-center gap-4">
                <AppButton
                    title="En Proceso"
                    state="default"
                    rounded
                    onClick={() => {
                        handleState('IN_PROCESS')
                    }}
                    className={`text-sm font-normal ${
                        filter.states.includes('IN_PROCESS') ? 'active' : ''
                    }`}
                />
                <AppButton
                    title="Finalizado"
                    state="default"
                    rounded
                    onClick={() => {
                        handleState('FINISHED')
                    }}
                    className={`text-sm font-normal ${
                        filter.states.includes('FINISHED') ? 'active' : ''
                    }`}
                />
                <AppButton
                    title="Cancelado"
                    state="default"
                    rounded
                    onClick={() => {
                        handleState('CANCELED')
                    }}
                    className={`text-sm font-normal ${
                        filter.states.includes('CANCELED') ? 'active' : ''
                    }`}
                />
                <AppDate
                    sendDate={(data) => {
                        handleDateChange(data)
                    }}
                />
            </div>
        </div>
    )
}

export default SearchFilter
