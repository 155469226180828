import { UserLoginError } from './interfaces/auth'
import {trackingAuth} from '../config/api/tracking'
export const fetchUser = async (apiToken: string) => {
    try {
        const headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + apiToken
        }
        const response = await trackingAuth.get(
            '/profile',
            { headers }
        )
        return response.data
    } catch (e) {
        // Sentry.Native.captureException(e)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw e.response.data as UserLoginError
    }
}
