import OrderItem from 'components/atoms/OrderItem/OrderItem'
import OrderList from 'components/molecules/OrderList/OrderList'
import React from 'react'
import { Order } from '../../../store/interfaces/order'


interface Props {
    orders: Order[]
}

export default function OrdersList({ orders }: Props) {
    return <OrderList component={OrderItem} list={orders} />
}
