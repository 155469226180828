import AppButton from 'components/atoms/AppButton/AppButton'
import AppInput from 'components/atoms/AppInput/AppInput'
import CardResumeMessage from 'components/atoms/CardResumeMessage/CardResumeMessage'
import React, { useState, useEffect, ChangeEvent } from 'react'
import './styles.css'
import upload from '../../../assets/Upload.png'
import AppSelect from '../../atoms/AppSelect/AppSelect'
import { getTemplatesRules, Template } from '../../../services/templateService'
import Message, { MessageProps } from '../../atoms/Message/Message'

interface formValue {
    description: string
    template_id: number
}

interface Props {
    submit: (formValue: formValue) => void
    deleteFile: () => void
    file: string
    withCriteria: boolean
}

export default function OrderForm({
    submit,
    file,
    deleteFile,
    withCriteria,
}: Props) {
    const [description, setDescription] = useState<string>('')
    const [template, setTemplate] = useState(0)
    const [fetchingTemplate, setFetchingTemplate] = useState(true)
    const [listTemplate, setListTemplate] = useState([])
    const [message, setMessage] = useState<MessageProps>({
        message: '',
        type: 'default',
    })

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setTemplate(parseInt(event.target.value))
    }

    const convertArray = () => {
        if (listTemplate.length > 0) {
            return listTemplate.map((item: Template) => ({
                value: item.id,
                label: item.name,
            }))
        }
        return []
    }

    useEffect(() => {
        getTemplatesRules().then((templates) => {
            setListTemplate(templates.data)
            if (templates.data.length === 0 && withCriteria) {
                setMessage({
                    message:
                        'No es posible crear una orden en este momento, no posee plantillas para asignar',
                    type: 'error',
                })
            } else {
                setFetchingTemplate(false)
            }
        })
    }, [])

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                submit({
                    description,
                    template_id: template,
                })
            }}
        >
            <div className="box px-5 flex flex-col items-center">
                <CardResumeMessage
                    title={file}
                    onClose={deleteFile}
                    icon={upload}
                />
                <div className="w-full">
                    {message.message && <Message {...message} />}
                </div>
                <div className="mt-4 bg-white shadow-md rounded-3xl py-10 px-7 w-full">
                    <div className={'mb-4'}>
                        <AppInput
                            disabled={message.type === 'error'}
                            label={'Nombre de la orden'}
                            placeholder={'Nombre de la orden'}
                            onChange={(nextText) =>
                                setDescription(nextText.target.value)
                            }
                        />
                    </div>
                    {withCriteria ? (
                        <AppSelect
                            disabled={
                                message.type === 'error' || fetchingTemplate
                            }
                            label={'Plantilla'}
                            onChange={handleChange}
                            placeholder={'Seleccionar una Plantilla'}
                            options={convertArray()}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <AppButton
                    buttonType={'submit'}
                    className="mt-16 font-bold"
                    title={'Cargar Importaciones'}
                    disabled={
                        withCriteria
                            ? file === null ||
                              description === '' ||
                              template === 0
                            : file === null || description === ''
                    }
                />
            </div>
        </form>
    )
}
