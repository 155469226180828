import React from 'react'
import RuleItem from '../RuleItem/RuleItem'
import { Rule } from 'interfaces/rule'

interface Props {
    rules: Rule[]
    criterias_selected: string[]
}

export default function RuleList({ rules, criterias_selected }: Props) {
    return (
        <div className="mt-8 overflow-y-auto" style={{ height: '500px' }}>
            {rules.map((rule, index) => {
                return (
                    <RuleItem
                        key={index}
                        title={rule.name}
                        criterias={rule.criterias}
                        criterias_selected={criterias_selected}
                    />
                )
            })}
        </div>
    )
}
