import { ReactNode, useState } from 'react'
import { ListItem } from '../../../interfaces/order'
import { updateState } from '../../../services/ordersService'
import { orderStates } from '../../../utils/constants'
import AppModal from '../../atoms/AppModal/AppModal'
import {
    initialOrder,
    updateOrder,
} from '../../../store/slices/order/orderSlice'
import { AppDispatch } from '../../../store/store'
import { useDispatch } from 'react-redux'
import { Order } from '../../../store/interfaces/order'
import ButtonNextNavigation from 'components/atoms/ButtonNextNavigation/ButtonNextNavigation'
import ButtonOrderDetailInProcessNavigation from 'components/atoms/ButtonOrderDetailInProcessNavigation/ButtonOrderDetailInProcessNavigation'
import { useNavigate } from 'react-router-dom'

interface Props {
    component: (data: ListItem) => ReactNode
    list: Order[]
}

const getButtons = (
    state: orderStates | undefined,
    id: number,
    logoMap: Record<string, (id: number) => JSX.Element>
) => (state && logoMap[state]?.(id)) || <></>

const OrderList = ({ component: ChildComponent, list }: Props) => {
    const navigate = useNavigate()
    const [confirmModal, setConfirmModal] = useState(false)
    const [orderSelected, setOrderSelected] = useState<Order>(initialOrder)
    const dispatch: AppDispatch = useDispatch()
    const handleChangeState = () => {
        if (orderSelected.id && orderSelected.state)
            updateState(orderSelected.id, orderSelected.state)
                .then(() => {
                    setConfirmModal(false)
                    setOrderSelected(initialOrder)
                    dispatch(updateOrder(orderSelected))
                })
                .catch((e) => {
                    alert(e.message)
                })
    }

    const logoMap = {
        IN_PROCESS: (id: number) => (
            <>
                <ButtonOrderDetailInProcessNavigation
                    onClick={() => navigate(`/orders/show/details/${id}`)}
                />
                <ButtonNextNavigation
                    onClick={() => navigate(`/orders/show/${id}`)}
                />
            </>
        ),
      PENDING: () => (
        <>

        </>
      ),
        FINISHED: (id: number) => (
            <>
                <ButtonOrderDetailInProcessNavigation
                    onClick={() => navigate(`/orders/show/details/${id}`)}
                />
                <ButtonNextNavigation
                    onClick={() => navigate(`/orders/show/${id}`)}
                />
            </>
        ),
        CANCELED: (id: number) => (
            <ButtonNextNavigation
                onClick={() => navigate(`/orders/show/${id}`)}
            />
        ),
        FAILED: (id: number) => (
            <ButtonNextNavigation
                onClick={() => navigate(`/orders/show/${id}`)}
            />
        ),
    }

    return (
        <>
            <AppModal
                title={
                    '¿Estás seguro de que deseas realizar esta acción? Esta acción es irreversible y no se podrá deshacer.'
                }
                onClose={() => setConfirmModal(false)}
                handleConfirm={() => handleChangeState()}
                isOpen={confirmModal}
            />
            {list.map((o, index) => {
                return (
                    <ChildComponent
                        id={o.id}
                        key={index}
                        created_at={o.created_at}
                        description={o.description}
                        state={o.state}
                        onChange={(state: orderStates) => {
                            setConfirmModal(true)
                            setOrderSelected({ ...o, state })
                        }}
                    >
                        {getButtons(o.state, o.id, logoMap)}
                    </ChildComponent>
                )
            })}
        </>
    )
}

export default OrderList
