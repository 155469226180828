import { CriteriaElement } from 'interfaces/rule'
import React, { useEffect, useState } from 'react'
import Select from 'react-tailwindcss-select'
import { SelectValue } from 'react-tailwindcss-select/dist/components/type'
import {
    getClients,
    getServiceCodes,
    getServiceTypes,
} from 'services/rulesService'
import { LoaderTransparent } from '../LoaderTransparent/LoaderTransparent'

interface Props {
    criteriaTypeId: number
    label: string
    criteria: string
    onChange: (e: CriteriaElement) => void
}

interface Option {
    label: string
    value: string
}

const ServiceCodeCriteria = ({
    label,
    criteriaTypeId,
    criteria,
    onChange,
}: Props) => {
    const [options, setOptions] = useState<Option[]>([])
    const [selectedOptions, setSelectedOptions] = useState<SelectValue>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)

            let dataPromise
            switch (criteria) {
                case 'service_code':
                    dataPromise = getServiceCodes()
                    break
                case 'service_types':
                    dataPromise = getServiceTypes()
                    break
                case 'clients':
                    dataPromise = getClients()
                    break
                default:
                    dataPromise = null
            }

            const data = await dataPromise

            if (data && data.data) {
                const transformedOptions = data.data
                    .filter(
                        (obj: { name: string; code: string }) =>
                            obj.name !== null
                    )
                    .map((obj: { name: string; code: string }) => ({
                        label: `${obj.name} - ${obj.code}`,
                        value: obj.code,
                    }))

                setOptions(transformedOptions)
            }

            setLoading(false)
        }
        fetchData()
    }, [])

    const handleChange = (val: SelectValue | null) => {
        setSelectedOptions(val)
    }

    useEffect(() => {
        onChange(criteriaData())
    }, [selectedOptions])

    const criteriaData = (): CriteriaElement => {
        return {
            criteria_type_id: criteriaTypeId,
            operator: '=',
            first_value: Array.isArray(selectedOptions)
                ? selectedOptions.map((item: Option) => item.value).join(',')
                : '',
            second_value: '',
        }
    }

    return (
        <>
            {loading ? (
                <LoaderTransparent />
            ) : (
                <div className="mt-7">
                    <p className="text-sm">{label}</p>
                    <div className="w-3/4">
                        <Select
                            value={selectedOptions}
                            onChange={handleChange}
                            options={options}
                            primaryColor={'sky'}
                            isMultiple
                            isSearchable
                            placeholder={label}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default ServiceCodeCriteria
