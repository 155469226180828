interface Props {
    component: React.ComponentType
    subtitle?: string
}

const ResumeCard = ({ component: ChildComponent, subtitle }: Props) => {
    return (
        <div className="w-80 h-48 bg-white shadow-md rounded-3xl flex justify-between items-center flex-col p-9">
            <ChildComponent />
            <p className="text-xs font-normal text-slate-600">{subtitle}</p>
        </div>
    )
}

export default ResumeCard
