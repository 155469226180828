import React from 'react'
import MenuItem from 'components/atoms/MenuItem/MenuItem'
import { MenuItemProps } from 'interfaces/menu'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearUser } from '../../../store/slices/user'
import { clearToken } from '../../../store/slices/auth'

interface Props {
    data: MenuItemProps[]
}

export default function MenuList({ data }: Props) {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const logoutHandle = () => {
        dispatch(clearUser())
        dispatch(clearToken())
        navigate('/')
    }

    const listItems = data.map((item, index) => (
        <MenuItem
            key={index}
            title={item.title}
            url={item.url}
            state={item.state}
            active={location.pathname.includes(item.url)}
            onClick={(url) => {
                if (url === 'logout') {
                    logoutHandle()
                } else {
                    navigate('/' + url)
                }
            }}
        />
    ))
    return (
        <div className="menu-list shadow-md bg-white rounded-3xl px-7 py-8 w-44">
            {listItems}
        </div>
    )
}
