import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// import DashboardPage from '../components/pages/Dashboard/DashboardPage'
import OrdersPage from '../components/pages/OrdersPage/OrdersPage'
import OrdersListPage from 'components/pages/OrdersListPage/OrdersListPage'
import CreateTemplatePage from 'components/pages/CreateTemplatesPage/CreateTemplatePage'
import RulesListPage from 'components/pages/RulesListPage/RulesListPage'
import ShowTemplatePage from 'components/pages/ShowTemplatePage/ShowTemplatePage'
import AssignRulePage from 'components/pages/AssignRulePage/AssignRulePage'
import RulesPage from '../components/pages/RulesPage/RulesPage'
import ShowOrderPage from 'components/pages/ShowOrderPage/ShowOrderPage'
import TemplatesListPage from 'components/pages/TemplatesListPage/TemplatesListPage'
import ShowOrderDetailsPage from 'components/pages/ShowOrderDetailsPage/ShowOrderDetailsPage'

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/orders" />} />
            <Route path="/orders" element={<OrdersListPage />} />
            <Route path="/orders/create" element={<OrdersPage />} />
            <Route
                path="/orders/show/details/:orderId"
                element={<ShowOrderDetailsPage />}
            />
            <Route path="/templates" element={<TemplatesListPage />} />
            <Route path="/templates/create" element={<CreateTemplatePage />} />
            <Route
                path="/templates/show/:templateId"
                element={<ShowTemplatePage />}
            />
            <Route path="/orders/show/:orderId" element={<ShowOrderPage />} />
            <Route path="/rules" element={<RulesListPage />} />
            <Route path="/templates/edit/:id" element={<AssignRulePage />} />
            <Route path="/rules/create" element={<RulesPage />} />
            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default AppRoutes
