import React, { useEffect } from 'react'
import LoginForm from './components/LoginForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../../store/slices/auth'
import { AppDispatch, RootState } from '../../../store/store'
import { fetchUser, setChecking } from '../../../store/slices/user'

const LoginPage = () => {
    const dispatch:AppDispatch = useDispatch()
    const {isLoading, errorMessage,apiToken} = useSelector((state:RootState) => state.auth)

    useEffect(() => {
        if(apiToken) {
            dispatch(setChecking())
            dispatch(fetchUser(apiToken))
        }
    }, [apiToken])



    return (
        <div className="flex items-center flex-col h-screen justify-center bg-gray-50">
            <div
                className="shadow-md flex flex-row w-3/4 content-center rounded-3xl overflow-hidden max-w-5xl mb-12 bg-white">
                <div className="w-1/2 p-20 flex flex-col ">
                    <img
                        src={require('../../../assets/Icon.png')}
                        className="img-logo w-16 mb-12 self-center"
                        alt="Logo"
                    />
                    <LoginForm onSubmit={(data) => dispatch(fetchData(data))} isLoading={isLoading} errorMessage={errorMessage ?? errorMessage} />
                </div>
                <div className="w-1/2 p-9 bg-login-message bg-cover rounded-xl">
                    <h1 className="text-white text-5xl font-bold mb-7">Clasifica, Optimiza, Conquista</h1>
                    <p className="text-white leading-9">Transforma tu almacén en un espacio eficiente con nuestra
                        aplicación de clasificación de
                        paquetes.
                        Organiza, clasifica y agrupa tus paquetes de manera eficiente, optimizando tu operación y
                        maximizando tu productividad</p>
                </div>
            </div>
            <small className="flex justify-center items-center text-gray-800">powered by <img
                src={require('../../../assets/mailamericasLogoText.png')}
                className="img-logo w-24 mb-2.5"
                alt="Logo"
            /></small>
        </div>
    )
}

export default LoginPage
