import React from 'react'
import Layout from '../../../layout/Layout'
import HeaderPageSection from '../../molecules/HeaderPageSection/HeaderPageSection'
import CreateTemplates from 'components/organisms/CreateTemplates/CreateTemplates'

export default function CreateTemplatePage() {
    return (
        <Layout>
            <HeaderPageSection />
            <CreateTemplates />
        </Layout>
    )
}
