const IN_PROCESS = 'IN_PROCESS';
const FINISHED = 'FINISHED';
const CANCELED = 'CANCELED';
const FAILED = 'FAILED';
const PENDING = 'PENDING';
export type orderStates = 'PENDING' | 'IN_PROCESS' | 'FINISHED' | 'CANCELED' | 'FAILED'

export const orderStatesSelect = {
  [IN_PROCESS]: {
    label: 'En Proceso',
    value: IN_PROCESS,
  },
  [PENDING]: {
    label: 'Pendiente',
    value: PENDING,
  },
  [FINISHED]: {
    label: 'Finalizado',
    value: FINISHED,
  },
  [CANCELED]: {
    label: 'Cancelado',
    value: CANCELED,
  },
  [FAILED]: {
    label: 'Fallido',
    value: FAILED,
  },
};

export const orderChangeStates = [orderStatesSelect[IN_PROCESS], orderStatesSelect[CANCELED], orderStatesSelect[FINISHED]];
