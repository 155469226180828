import React, { useState, useEffect } from 'react'
import AppButton from 'components/atoms/AppButton/AppButton'
import AppInput from 'components/atoms/AppInput/AppInput'
import { CriteriaList, Filter } from 'interfaces/rule'

interface Props {
    criterias: CriteriaList[]
    onChange: (data: Filter) => void
}

export default function RulesFilter({ criterias, onChange }: Props) {
    const [filter, setFilter] = useState<Filter>({
        name: '',
        criteria_types: [],
        page: 1,
    })

    const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            const target = e.target as HTMLInputElement
            setFilter((prevFilter) => ({
                ...prevFilter,
                name: target.value,
            }))
        }
    }
    //
    const handleCriteria = (value: number) => {
        setFilter((prevFilter) => {
            const currentCriterias = prevFilter.criteria_types
            // Verifica si el estado ya existe en el array
            const exists = currentCriterias.includes(value)
            // Si existe, crea un nuevo array sin ese estado
            if (exists) {
                return {
                    ...prevFilter,
                    criteria_types: currentCriterias.filter(
                        (criteria_types) => criteria_types !== value
                    ),
                }
            } else {
                // Si no existe, añade el estado al array
                return {
                    ...prevFilter,
                    criteria_types: [...currentCriterias, value],
                }
            }
        })
    }

    // Llamar a onChange con el filtro actualizado cada vez que cambie
    useEffect(() => {
        onChange(filter)
    }, [filter])

    return (
        <div className=" flex justify-end gap-5 py-5 px-0">
            <div className=" flex gap-4 flex-wrap whitespace-nowrap">
                {criterias.map((criteria, index) => {
                    return (
                        <AppButton
                            key={index}
                            title={criteria.name}
                            state="default"
                            rounded
                            onClick={() => {
                                handleCriteria(criteria.id)
                            }}
                            className={`text-sm font-normal ${
                                filter.criteria_types.includes(criteria.id)
                                    ? 'active'
                                    : ''
                            }`}
                        />
                    )
                })}
            </div>
            <div className="w-48">
                <AppInput
                    placeholder="Buscar regla..."
                    name="rule"
                    onKeyDown={handleSearch}
                />
            </div>
        </div>
    )
}
