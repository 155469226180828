import React, { useState } from 'react'
import BreadcrumbTitle from '../../atoms/BreadcrumbTitle/BreadcrumbTitle'
import { useNavigate } from 'react-router-dom'
import CardInputRuleForm from 'components/molecules/CardInputRuleForm/CardInputRuleForm'
import CardResumeMessage from 'components/atoms/CardResumeMessage/CardResumeMessage'
import checkIcon from '../../../assets/Checkmark.png'
import AppButton from 'components/atoms/AppButton/AppButton'
import { create } from '../../../services/templateService'
import Message from '../../atoms/Message/Message'

interface Data {
    data?: { id: number; name: string }
    error: boolean
    message: string
}

export default function CreateTemplates() {
    const navigate = useNavigate()
    const [stateTemplate, setStateTemplate] = useState<Data | null>(null)
    const [newTemplate, setNewTemplate] = useState({
        name: '',
        id: 0,
        description: '',
    })

    const createTemplate = async () => {
        try {
            const data = await create(newTemplate)
            setStateTemplate(data)
            // @ts-expect-error type validation error
        } catch (error: {
            error?: boolean
            message?: string
        }) {
            setStateTemplate({
                ...stateTemplate,
                message: error.message,
                error: true,
            })
        }
    }

    return (
        <div className="px-10">
            <BreadcrumbTitle
                title="Nueva Plantilla"
                goToBack={() => navigate('/templates')}
            />
            <div className="m-auto w-8/12 lg:w-10/12 h-full  pt-5 ">
                {stateTemplate !== null && stateTemplate.error == false ? (
                    <div className="w-[510px] m-auto flex flex-col justify-center">
                        <CardResumeMessage
                            title={`${newTemplate.name} Creada con exito`}
                            icon={checkIcon}
                        />
                        <div className="flex justify-center">
                            <AppButton
                                title="Agrega regla"
                                onClick={() => {
                                    navigate(
                                        `/templates/edit/${stateTemplate.data?.id}`
                                    )
                                }}
                                className="mt-5"
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <CardInputRuleForm
                            title="Nombre de plantilla"
                            subtitle="Ingrese el nombre para crear la nueva plantilla"
                            onChange={(data) => {
                                setNewTemplate({ ...newTemplate, name: data })
                            }}
                            label="Nombre Plantilla"
                            onClick={createTemplate}
                            disabled={newTemplate.name === ''}
                        />
                        {stateTemplate !== null && stateTemplate?.error && (
                            <Message
                                message={stateTemplate?.message}
                                type={'error'}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
