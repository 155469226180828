import React from 'react'
import Orders from 'components/organisms/Orders/Orders'
import Layout from '../../../layout/Layout'
import HeaderPageSection from '../../molecules/HeaderPageSection/HeaderPageSection'

export default function OrdersPage() {
    return (
        <Layout>
            <HeaderPageSection />
            <Orders />
        </Layout>
    )
}
