import React from 'react'

export default function Loader() {
    return (
        <div className="h-screen w-full text-center flex justify-center flex-col items-center">
            <img
                src={require('../../../assets/Icon.png')}
                className="img-logo"
                alt="Logo"
            />
            <p className="mt-2 text-ebony-950/75">Cargando...</p>
        </div>
    )
}
