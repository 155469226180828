import React from 'react'

interface Props {
    title: string
    subtitle?: string
    goToBack?: () => void
}

const BreadcrumbTitle = ({ title, subtitle, goToBack }: Props) => {
    return (
        <div>
            <div className="flex flex-column items-center">
                {goToBack && (
                    <img
                        src={require('../../../assets/back-icon.png')}
                        alt="Back"
                        className="cursor-pointer"
                        onClick={goToBack}
                    />
                )}
                <div
                    className={`${goToBack ? 'ml-3 ' : ''} ${subtitle && goToBack ? 'mt-5' : ''
                        }`}
                >
                    <div className="text-ebony-950 text-3xl font-extrabold">
                        {title}
                    </div>
                    {subtitle && (
                        <div className="flex justify-start text-sm text-gray-500 mt-3">
                            {subtitle}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BreadcrumbTitle
