import React, { useState } from 'react'
import AppInput from '../../../atoms/AppInput/AppInput'
import AppButton from '../../../atoms/AppButton/AppButton'
import { patterns } from '../../../../utils/patterns'
import { UserLogin } from '../../../../interfaces/login'
import Message from '../../../atoms/Message/Message'

interface Props {
    onSubmit: (data: UserLogin) => void
    errorMessage?: string | null
    isLoading: boolean
}

const LoginForm = ({ onSubmit, isLoading, errorMessage }: Props) => {
    const [emailError, setEmailError] = useState(false)
    const [data, setData] = useState<UserLogin>({
        username: '',
        password: '',
    })

    const validateEmail = (nextText: string) => {
        setEmailError(!patterns.email.test(nextText))
        setData({ ...data, username: nextText.toLowerCase() })
    }

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(data)
            }}
        >
            <div className="mb-5">
                <AppInput
                    placeholder={'Correo electrónico'}
                    onChange={(e) => validateEmail(e.target.value)}
                />
                {emailError && (
                    <small className="text-red-500">
                        Error de formato, por favor verifique
                    </small>
                )}
            </div>
            <AppInput
                type={'password'}
                className="mb-1"
                placeholder={'Contraseña'}
                onChange={(nextText) =>
                    setData({ ...data, password: nextText.target.value })
                }
            />
            {errorMessage && <Message message={errorMessage} type={'error'} />}

            <div className="mt-20 flex flex-col items-center">
                <AppButton
                    className={'mb-5 font-bold'}
                    title={isLoading ? 'Cargando...' : 'Iniciar sesión'}
                    buttonType={'submit'}
                    disabled={emailError || data.password === '' || isLoading}
                    onSubmit={(e) => {
                        e.preventDefault()
                        onSubmit(data)
                    }}
                />
                {/*<AppButton title={'Olvide mi contraseña'} state={'ghost'} size={'sm'} />*/}
            </div>
        </form>
    )
}

export default LoginForm
