import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Order, OrderState } from '../../interfaces/order'

export const initialOrder: Order = {
    id: 0,
    state: undefined,
    description: '',
    created_at: undefined,
    packageCount: ''
}

const initialState: OrderState = {
    totalItems: 0,
    items:      [],
    page:       0,
    size:       0,
    loading: false
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setState: (state, action:PayloadAction<OrderState>) => {
          state.items = action.payload.items
          state.page = action.payload.page
          state.loading = action.payload.loading
          state.size = action.payload.size
          state.totalItems = action.payload.totalItems
        },
        getOrders: (state) => {
            state.loading = true
        },
        updateOrder: (state, action:PayloadAction<Order>) => {
            state.items = state.items.map(o => {
                if(o.id === action.payload.id)
                    return {...o, state: action.payload.state}
                return o
            })
        }
    }
})

// Action creators are generated for each case reducer function
export const { getOrders, updateOrder, setState } = orderSlice.actions

export default orderSlice.reducer
