import React from 'react'

interface Tab {
    label: string
    value: string
}

interface TabItem extends Tab {
    onClick: (value: string) => void
    isActive: boolean
}
const TabListItem = ({ label, isActive, value, onClick }: TabItem) => (
    <label
        className="ml-6 flex flex-col cursor-pointer"
        onClick={() => onClick(value)}
    >
        <span className={'mb-3 ' + (isActive ? 'text-sky-500 font-bold' : '')}>
            {label}
        </span>
        <span
            className={'h-1 rounded-3xl ' + (isActive ? 'bg-sky-500/30' : '')}
        ></span>
    </label>
)

interface Props {
    tabs: Tab[]
    value: string
    onChange: (value: string) => void
}
const TabsList = ({ tabs, onChange, value }: Props) => {
    return (
        <div className="flex">
            {tabs &&
                tabs.map((t, index) => (
                    <TabListItem
                        key={index}
                        label={t.label}
                        isActive={t.value === value}
                        onClick={(v) => onChange(v)}
                        value={t.value}
                    />
                ))}
        </div>
    )
}

export default TabsList
