import React from 'react'

interface Props {
    chute: {
        chuteNumber: number
        totalPackages: number
    }
}

const NumberAndTotalChutes = ({ chute }: Props) => {
    return (
        <div className="bg-white rounded-lg border p-1.5 flex items-center justify-around">
            <p className="w-[30px] h-[30px] flex justify-center items-center mr-3 rounded-full bg-blue-100">
                {chute.chuteNumber}
            </p>
            <p>{chute.totalPackages}</p>
        </div>
    )
}

export default NumberAndTotalChutes
