import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../store/store'
import { clearToken, setApiToken } from '../store/slices/auth'
import { clearUser, fetchUser, setChecking } from '../store/slices/user'

const useCheckAuth = () => {
    const { status } = useSelector((state: RootState) => state.user)
    const dispatch: AppDispatch = useDispatch()

    useEffect(() => {
        const checkAuth = async () => {
            dispatch(setChecking())
            try {
                const token = await localStorage.getItem('token')
                if (token) {
                    dispatch(setApiToken(token))
                    dispatch(fetchUser(token))
                } else {
                    throw new Error('No token found')
                }
            } catch (e) {
                dispatch(clearToken())
                dispatch(clearUser())
            }
        }

        checkAuth()
    }, [])
    return status
}

export default useCheckAuth
