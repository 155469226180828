import React from 'react'
import { MenuItemProps } from 'interfaces/menu'
import './styles.css'

const MenuItem = ({ title, state, url, onClick, active }: MenuItemProps) => {
    return (
        <div
            className={`menu-item flex justify-center py-1.5 px-3 rounded-lg text-sm font-normal mb-4 bg-shark-4 text-ebony-950 ${
                active && ' text-white bg-shark-950'
            } ${state === 'sign-off' && ' text-red-600'}`}
        >
            {/* <a href={`/customer/${item._id}`} >{item.get('firstName')} {item.get('lastName')}</a> */}
            <a href={`#`} onClick={() => onClick && onClick(url)}>
                {title}
            </a>
        </div>
    )
}

export default MenuItem
