import React, { useEffect, useState } from 'react'
import AppButton from '../../atoms/AppButton/AppButton'
import TabsList from '../../molecules/TabsList/TabsList'
import OneValueCriteriaForm from '../../molecules/OneValueCriteriaForm/OneValueCriteriaForm'
import MeasurementCriteriaForm from '../../pages/RulesPage/components/MeasurementCriteriaForm'
import InputFileUpload from '../../atoms/InputFileUpload/InputFileUpload'
import SwitchCriteriaForm from '../../molecules/CheckboxCriteriaForm/SwitchCriteriaForm'
import {
    CriteriaData,
    CriteriaElement,
    CriteriaList,
    PostalStructure,
} from '../../../interfaces/rule'
import RangeCriteriaForm, {
    WeightForm,
} from '../../pages/RulesPage/components/RangeCriteriaForm'
import ServiceCodeCriteria from '../../molecules/ServiceCodeCriteria/ServiceCodeCriteria'
// import GeolocationCriteriaForm from 'components/pages/GroupsPage/components/GeolocationCriteriaForm'
// import { ListItem } from 'components/molecules/SelectList/SelectList'
import GeolocationCriteriaForm, {
    Country,
} from 'components/pages/RulesPage/components/GeolocationCriteriaForm'
import { getCountries, getPostalStructures } from 'services/rulesService'

interface Props {
    onClick: (values: CriteriaElement[]) => void
    onBack: () => void
    criterias: CriteriaList[]
    loading: boolean
}

const CriteriaConfigForm = ({ criterias, onClick, onBack, loading }: Props) => {
    const [criteriaSelected, setCriteriaSelected] = useState<string>(
        criterias[0] ? criterias[0].key : ''
    )
    const [criteriaValues, setCriteriaValues] = useState<CriteriaElement[]>([])
    const [disabled, setDisabled] = useState(true)
    const [countries, setCountries] = useState<Country[]>([])
    const [localList1, setLocalList1] = useState<PostalStructure[]>([])

    const updateCriteriaValues = (criteria: CriteriaElement) => {
        setCriteriaValues([
            ...criteriaValues.filter(
                (c) => criteria.criteria_type_id !== c.criteria_type_id
            ),
            criteria,
        ])
    }

    const validateArray = () => {
        const valid = criteriaValues.filter((obj) => {
            let hasEmptyData = false
            let hasEmptyValues = false
            if (obj.data) {
                hasEmptyData = obj.data.some(
                    (dataObj) =>
                        !(
                            dataObj.first_value === '' &&
                            dataObj.second_value === ''
                        )
                )
            } else {
                if (obj.second_value)
                    hasEmptyValues = !(
                        obj.first_value === '' && obj.second_value === ''
                    )
                else hasEmptyValues = !(obj.first_value === '')
            }
            return hasEmptyData || hasEmptyValues
        })

        return criteriaValues.length === valid.length
    }

    useEffect(() => {
        setDisabled(validateArray())
    }, [criteriaValues])

    useEffect(() => {
        const criteriaValues: CriteriaElement[] = []
        criterias.forEach((c) => {
            switch (c.key) {
                case 'weight':
                case 'declared_value':
                case 'postal_code_range':
                    criteriaValues.push(parseTwoInputs(c))
                    break
                case 'distribution_provider':
                case 'service_code':
                case 'clients':
                case 'service_types':
                    criteriaValues.push(parseOneInput(c))
                    break
                case 'measurements':
                    criteriaValues.push(parseMeasurementsInputs(c))
                    break
                case 'postal_code_list':
                    criteriaValues.push(parseFile(c))
                    break
                case 'ddu_ddp':
                    criteriaValues.push(parseSwitchCriteria(c, 'false'))
                    break
            }
        })

        setCriteriaValues(criteriaValues)

        const fetchCountries = async () => {
            try {
                const data = await getCountries()
                setCountries((prevCountries) => [
                    ...prevCountries,
                    ...data.data,
                ])
            } catch (error) {
                console.error(error)
            }
        }

        if (
            criterias.some((obj) => obj.key === 'states' || obj.key === 'town')
        ) {
            fetchCountries()
        }
    }, [criterias])

    const parseOneInput = ({ id }: CriteriaList, v: string | boolean = '') =>
        Object.assign(
            {},
            { criteria_type_id: id, operator: '=', first_value: v }
        )
    const parseTwoInputs = (
        { id }: CriteriaList,
        v: WeightForm = { first_value: '', second_value: '', operator: '' }
    ) => Object.assign({}, { criteria_type_id: id, ...v })
    const parseMeasurementsInputs = (
        { id }: CriteriaList,
        v: CriteriaData[] = [
            { first_value: '', second_value: '', operator: '', value: '' },
        ]
    ) => Object.assign({}, { criteria_type_id: id, data: v })

    const parseFile = ({ id }: CriteriaList, base64: string = '') =>
        Object.assign({}, { criteria_type_id: id, file: base64 })

    const parseSwitchCriteria = ({ id }: CriteriaList, v: string) =>
        Object.assign(
            {},
            { criteria_type_id: id, operator: 'boolean', first_value: v }
        )

    const parseServiceCodeData = (v: CriteriaElement) =>
        Object.assign({}, { ...v })

    const parseGeolocationData = (v: CriteriaElement) =>
        Object.assign({}, { ...v })

    const OnHandleChangeList = async (
        val: string,
        level: number,
        item: number
    ) => {
        if (val) {
            try {
                const data = await getPostalStructures(val, level)
                setLocalList1((prevState) => ({
                    ...prevState,
                    [item]: data.data,
                }))
            } catch (error) {
                console.error('Error al obtener estructuras postales:', error)
            }
        }
    }

    const switchCriteria = (criteriaKey: string) => {
        const criteria = criterias.find((c) => c.key === criteriaKey)
        if (criteria)
            switch (criteriaKey) {
                case 'states':
                    return (
                        <GeolocationCriteriaForm
                            criteria_type_id={criteria.id}
                            title=""
                            countries={countries}
                            OnHandleChangeList={(val) =>
                                OnHandleChangeList(val, 1, criteria.id)
                            }
                            onChange={(e) =>
                                updateCriteriaValues(parseGeolocationData(e))
                            }
                            list1={localList1[criteria.id]}
                        />
                    )
                case 'town':
                    return (
                        <GeolocationCriteriaForm
                            criteria_type_id={criteria.id}
                            title=""
                            countries={countries}
                            OnHandleChangeList={(val) =>
                                OnHandleChangeList(val, 2, criteria.id)
                            }
                            onChange={(e) =>
                                updateCriteriaValues(parseGeolocationData(e))
                            }
                            list1={localList1[criteria.id]}
                        />
                    )
                case 'township':
                    return (
                        <GeolocationCriteriaForm
                            criteria_type_id={criteria.id}
                            title=""
                            countries={countries}
                            OnHandleChangeList={(val) =>
                                OnHandleChangeList(val, 3, criteria.id)
                            }
                            onChange={(e) =>
                                updateCriteriaValues(parseGeolocationData(e))
                            }
                            list1={localList1[criteria.id]}
                        />
                    )
                case 'weight':
                    return (
                        <RangeCriteriaForm
                            label={'Ingrese peso'}
                            placeholder={['Desde', 'Hasta']}
                            inputPlaceholders={['0.1kg', '0.2kg']}
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseTwoInputs(criteria, e)
                                )
                            }
                        />
                    )
                case 'declared_value':
                    return (
                        <RangeCriteriaForm
                            label={'Ingresa valor declarado'}
                            placeholder={['Desde', 'Hasta']}
                            inputPlaceholders={['111', '122']}
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseTwoInputs(criteria, e)
                                )
                            }
                        />
                    )
                case 'postal_code_range':
                    return (
                        <RangeCriteriaForm
                            label={'Ingrese rango código postal'}
                            placeholder={['Desde', 'Hasta']}
                            inputPlaceholders={['111', '122']}
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseTwoInputs(criteria, e)
                                )
                            }
                        />
                    )
                case 'measurements':
                    return (
                        <MeasurementCriteriaForm
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseMeasurementsInputs(criteria, e)
                                )
                            }
                        />
                    )
                case 'postal_code_list':
                    return (
                        <InputFileUpload
                            key={criteria.id}
                            accept=".xlsx"
                            base64
                            onSubmit={(file) =>
                                updateCriteriaValues(
                                    parseFile(criteria, file as string)
                                )
                            }
                        >
                            <div className="text-xs mt-1.5">
                                <span>
                                    Haga clic{' '}
                                    <a
                                        className="text-blue-600"
                                        href={require('../../../assets/zip_code_template.xlsx')}
                                        download={'zip_code_template.xlsx'}
                                    >
                                        aquí
                                    </a>{' '}
                                    para descargar un archivo de plantilla.
                                </span>
                            </div>
                        </InputFileUpload>
                    )
                case 'distribution_provider':
                    return (
                        <OneValueCriteriaForm
                            label={'Código de servicio'}
                            inputPlaceholder={'PRXXXX'}
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseOneInput(criteria, e.value)
                                )
                            }
                        />
                    )
                case 'service_code':
                    return (
                        <div>
                            <div>
                                <p className="text-sm">
                                    Ingresa Código de Servicio
                                </p>
                            </div>
                            <ServiceCodeCriteria
                                label="Código de Servicio"
                                criteriaTypeId={criteria.id}
                                criteria={criteria.key}
                                onChange={(e) =>
                                    updateCriteriaValues(
                                        parseServiceCodeData(e)
                                    )
                                }
                            />
                        </div>
                    )
                case 'service_types':
                    return (
                        <div>
                            <div>
                                <p className="text-sm">
                                    Ingresa Tipos de Servicio
                                </p>
                            </div>
                            <ServiceCodeCriteria
                                label="Tipos de Servicio"
                                criteriaTypeId={criteria.id}
                                criteria={criteria.key}
                                onChange={(e) =>
                                    updateCriteriaValues(
                                        parseServiceCodeData(e)
                                    )
                                }
                            />
                        </div>
                    )
                case 'clients':
                    return (
                        <div>
                            <div>
                                <p className="text-sm">Selecciona Clientes</p>
                            </div>
                            <ServiceCodeCriteria
                                label="Clientes"
                                criteriaTypeId={criteria.id}
                                criteria={criteria.key}
                                onChange={(e) =>
                                    updateCriteriaValues(
                                        parseServiceCodeData(e)
                                    )
                                }
                            />
                        </div>
                    )
                case 'ddu_ddp':
                    return (
                        <SwitchCriteriaForm
                            label={'Seleccione si desea configurar DDU o DDP'}
                            checkPrefix="DDU"
                            checkSuffix="DDP"
                            onChange={(e) =>
                                updateCriteriaValues(
                                    parseSwitchCriteria(criteria, e.toString())
                                )
                            }
                            checked={false}
                        />
                    )
                default:
                    return <></>
            }
    }
    return (
        <>
            <div
                className={
                    'shadow-md bg-white rounded-3xl px-7 pt-5 flex flex-col w-full'
                }
            >
                <div className="flex justify-between items-center">
                    <p className="text-xl font-bold mb-3">Criterios</p>
                    <img
                        src={require('../../../assets/edit.png')}
                        className="cursor-pointer w-5 h-5"
                        alt="Edit"
                        onClick={() => onBack()}
                    />
                </div>
                <TabsList
                    tabs={criterias.map((c) => {
                        return {
                            value: c.key,
                            label: c.name,
                        }
                    })}
                    onChange={setCriteriaSelected}
                    value={criteriaSelected}
                />
            </div>
            <div
                className={
                    'shadow-md bg-white rounded-3xl px-7 py-8 flex w-full mt-6 mb-10'
                }
            >
                {criterias.map((c) => (
                    <div
                        key={c.key}
                        className="w-full"
                        style={{
                            display:
                                c.key === criteriaSelected ? 'block' : 'none',
                        }}
                    >
                        {switchCriteria(c.key)}
                    </div>
                ))}
            </div>
            <div className="text-center">
                <AppButton
                    title={loading ? 'Cargando...' : 'Crear Regla'}
                    disabled={loading ? true : !disabled}
                    onClick={() => onClick(criteriaValues)}
                />
            </div>
        </>
    )
}

export default CriteriaConfigForm
