import React from 'react'

interface Props {
    title: string
    description: string
    onClick: () => void
    withCriteria: boolean
    disabled?: boolean
}

const BigButton = ({ title, description, onClick, withCriteria }: Props) => {
    return (
        <div
            onClick={onClick}
            className={`shadow-md rounded-3xl px-10 py-8 cursor-pointer w-full ${
                withCriteria ? 'bg-white' : 'bg-shark-950'
            }`}
        >
            <h2
                className={`text-xl font-bold leading-8 ${
                    withCriteria ? 'text-ebony-950' : 'text-white'
                }`}
            >
                {title}
            </h2>
            <p
                className={`text-sm font-normal leading-5 ${
                    withCriteria ? 'text-ebony-950' : 'text-white'
                }`}
            >
                {description}
            </p>
        </div>
    )
}

export default BigButton
