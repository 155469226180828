import React from 'react';

interface Props {
    children: React.ReactNode;
}

const AppBox = ({children}: Props) => {
    return (
        <div className={'shadow-md bg-white rounded-3xl px-7 py-8 w-full mt-6'}>
            {children}
        </div>
    );
};

AppBox.displayName = 'AppBox';

export default AppBox;
